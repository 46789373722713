import Image from "next/image";
import { completedIcon, inActiveIcon, inProgressIcon } from "public/assets";
import "./StatusBubble.scss";

type statusBubbleProps = {
  status?: string;
  iconWidth?: number;
  iconHeight?: number;
  className?: string;
};

const StatusBubble = (props: statusBubbleProps) => {
  const { status, iconWidth, iconHeight, className } = props;

  const getIcon = () => {
    switch (status) {
      case "completed": {
        return completedIcon;
      }
      case "in_progress": {
        return inProgressIcon;
      }
      default: {
        return inActiveIcon;
      }
    }
  };

  return (
    <Image
      src={getIcon()}
      alt={`${status ? status : "inactive"} icon`}
      width={iconWidth}
      height={iconHeight}
      className={className}
    />
  );
};

export default StatusBubble;
