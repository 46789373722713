"use client";

import { CacheProvider } from "@chakra-ui/next-js";
import AppTheme from "../global-theme/AppTheme";
import { ChakraProvider } from "@/components/ChakraUiManager";
import React, { ReactNode, useContext, useState, useEffect } from "react";
import { Toast } from "@/components/common";
import { AppDispatch, setDocsMenu, setApiMenu, store } from "@/store";
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from "react-redux";
import TimeoutHandler from "@/components/common/TimeoutHandler/TimeoutHandler";
import UnsavedChangesProvider from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import Head from "next/head";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import { checkIfRestricted, deepCopy } from "@/utils/common";
import {
  getDocumentCenterNavigationItems,
  getAllAPILibraryNavigationItems,
} from "@/prismic-api/prismic-api-service";

import { getAppPrismicClient } from "@/store";
import { MultiTieredSideMenuDataModel } from "@/components/common/MultiTieredSideMenu/MultiTieredSideMenuDataModel";
import { usePathname } from "next/navigation";

export type UserInfo = {
  loading: boolean;
  signedIn: boolean;
  firstName: string;
  lastName: string;
  email: string;
  organization: number;
  fastProfile: any;
  superUser: boolean;
};

export type DocNavigationDataContextType = {
  docCenterNavigationItems: any[];
  breadCrumbs: any[];
  loading: boolean;
};

export type NavigationDocumentItemData = {
  uid: string;
  type: string;
  title: string;
  path: string;
};

export type ApiNavigationDocumentItemData = {
  docCenterNavigationItems: any[];
  breadCrumbs: any[];
  loading: boolean;
  nextDocumentItems: NavigationDocumentItemData[];
};

let defaultUserContext: {
  user: UserInfo;
  setUser: (user: UserInfo) => void;
} = {
  user: {
    loading: true,
    signedIn: false,
    firstName: "",
    lastName: "",
    email: "",
    organization: 0,
    fastProfile: {},
    superUser: false,
  },
  setUser: (user) => {},
};
export const UserContext = React.createContext(defaultUserContext);

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState(defaultUserContext.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const f = async () => {
      try {
        const response = await fetch("/console/my/account");
        const responseJson = await response.json();

        const responseFastProfile = await fetch("/console/my/fastProfile");
        const responseFastProfileJson =
          (await responseFastProfile.json()) || {};

        if (response.status === 403) {
          setUser((prevState) => ({
            ...prevState,
            loading: false,
          }));
        } else if (responseJson) {
          setUser({
            loading: false,
            signedIn: true,
            ...responseJson,
            fastProfile: { ...responseFastProfileJson },
          });
        }
      } catch (err) {
        //Catching 401 error
        //Resetting user loading state to false
        setUser((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    };

    f();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserInfo = () => {
  const { user, setUser } = useContext(UserContext);
  return { user, setUser };
};

export const useUserAuthenticated = () => {
  const { user } = useContext(UserContext);
  return user.loading == false && user.signedIn == true;
};

interface MenuContextProps {
  docsMenu: DocNavigationDataContextType;
  apisMenu: ApiNavigationDocumentItemData;
}

const docsDefaultContextVal: DocNavigationDataContextType = {
  docCenterNavigationItems: [],
  breadCrumbs: [],
  loading: true,
};

const apiDefaultContextVal: ApiNavigationDocumentItemData = {
  docCenterNavigationItems: [],
  breadCrumbs: [],
  loading: true,
  nextDocumentItems: [],
};

export const MenuContext = React.createContext<MenuContextProps>({
  docsMenu: docsDefaultContextVal,
  apisMenu: apiDefaultContextVal,
});
// export const ApisMenuContext = React.createContext(apiDefaultContextVal);

export const MenuProvider = ({ children }: { children: React.ReactNode }) => {
  const prismicClient: any = useSelector(getAppPrismicClient);
  const pathName = usePathname()?.replace(/\/$/, "");

  const [docsMenuData, setDocsMenuData] = useState(docsDefaultContextVal);
  const [apisMenuData, setApisMenuData] = useState(apiDefaultContextVal);
  const isAuthenticated = useUserAuthenticated();
  const dispatch = useDispatch<AppDispatch>();

  // Fetch docs data and set it
  useEffect(() => {
    const breadCrumbs: any = [];
    const buildNavigationItems = (
      topicData: any,
      title: string,
      prevDocBreadCrumb: string
    ) => {
      // console.log(topicData[0].data.topics[17].link.link_type);
      const topicToBuild = topicData.find((td: any) => td.uid === title);

      let navItems: MultiTieredSideMenuDataModel[] = [];
      if (title === "main")
        navItems.push({
          type: "item",
          title: "Home",
          childrens: [],
          path: ROUTE_BASE_URL.DOCUMENT_CENTER,
          isSelected: true,
          uid: ROUTE_BASE_URL.DOCUMENT_CENTER,
        });
      if (topicToBuild && topicToBuild.data.topics) {
        for (let topic of topicToBuild.data.topics) {
          let restricted = false;
          if (!isAuthenticated && checkIfRestricted(topic.document?.tags)) {
            restricted = true;
          }
          if (!restricted) {
            if (topic.child.uid === "test-navigation") {
              continue;
            }

            const title = topic.title[0].text;
            const defaultUID = title.toLowerCase().replaceAll(" ", "-");

            const isWebLink = topic.link.link_type === "Web";

            const docBreadCrumb = topic.child.uid
              ? `${prevDocBreadCrumb},${topic.title[0].text}`
              : prevDocBreadCrumb;

            let navItem: MultiTieredSideMenuDataModel = {
              title: title,
              uid: topic.document.uid ?? defaultUID,
              childrens: topic.child.uid
                ? buildNavigationItems(
                    topicData,
                    topic.child.uid,
                    docBreadCrumb
                  )
                : [],
              path: isWebLink
                ? topic.link.url
                : topic.document.uid
                  ? `${ROUTE_PATH["DOCUMENT_DETAIL"]}/${topic.document.type}/${topic.document.uid}`
                  : "",
              type: "item",
            };
            navItems.push(navItem);

            if (!breadCrumbs[topic.document.type]) {
              breadCrumbs[topic.document.type] = [];
            }
            breadCrumbs[topic.document.type][topic.document.uid] =
              docBreadCrumb;
          }
        }
      }
      return navItems;
    };
    getDocumentCenterNavigationItems(prismicClient).then((response) => {
      if (response) {
        const topObj: MultiTieredSideMenuDataModel = {
          type: "none",
          title: "",
          path: "",
          isSelected: false,
          childrens: [...buildNavigationItems(response, "main", "Docs")],
          uid: "",
        };
        const docData = deepCopy({
          docCenterNavigationItems: [topObj],
          breadCrumbs: breadCrumbs,
          loading: false,
        });
        setDocsMenuData(docData);
        dispatch(setDocsMenu(docData));
      }
    });
  }, [isAuthenticated]);

  // Fetch API data and set it
  useEffect(() => {
    const breadCrumbs: any = [];
    const nextDocumentItems: NavigationDocumentItemData[] = [];
    const buildNavigationItems = (
      topicData: any,
      title: string,
      prevBreadCrumb: string
    ) => {
      const topicToBuild = topicData.find((td: any) => td.uid === title);
      let navItems: MultiTieredSideMenuDataModel[] = [];
      if (topicToBuild && topicToBuild.data.topics) {
        for (let topic of topicToBuild.data.topics) {
          if (topic.child.uid === "test-navigation") {
            continue;
          }

          const title = topic.title[0].text;
          const defaultUID = title.toLowerCase().replaceAll(" ", "-");

          const breadCrumb = topic.child.uid
            ? `${prevBreadCrumb},${topic.title[0].text}`
            : prevBreadCrumb;

          let navItem: MultiTieredSideMenuDataModel = {
            title: topic.title[0].text,
            uid: topic.document.uid ?? defaultUID,
            childrens: topic.child.uid
              ? buildNavigationItems(topicData, topic.child.uid, breadCrumb)
              : [],
            path: topic.document.uid
              ? encodeURI(
                  `${ROUTE_PATH.API_LIBRARY_DETAIL}/${topic.document.type}/${topic.document.uid}`
                )
              : "",
            type: topic.type?.toLowerCase(),
            isSelected: pathName === topic.document.uid,
          };
          if (navItem.type === "item" && navItem.path.length > 0) {
            nextDocumentItems.push({
              type: navItem.type,
              uid: navItem.uid,
              title: navItem.title,
              path: navItem.path,
            });
          }
          navItems.push(navItem);

          if (!breadCrumbs[topic.document.type]) {
            breadCrumbs[topic.document.type] = [];
          }
          breadCrumbs[topic.document.type][topic.document.uid] = breadCrumb;
        }
      }
      return navItems;
    };
    getAllAPILibraryNavigationItems(prismicClient).then((response) => {
      if (response) {
        const topObj: MultiTieredSideMenuDataModel = {
          type: "none",
          title: "",
          path: "",
          isSelected: false,
          childrens: [
            {
              type: "item",
              title: "Home",
              childrens: [],
              path: ROUTE_BASE_URL.API_LIBRARY,
              isSelected: pathName === ROUTE_BASE_URL.API_LIBRARY,
              uid: ROUTE_BASE_URL.API_LIBRARY,
            },
            ...buildNavigationItems(response, "main", "API References Home"),
          ],
          uid: "",
        };

        const apiData = deepCopy({
          docCenterNavigationItems: [topObj],
          breadCrumbs: breadCrumbs,
          loading: false,
          nextDocumentItems,
        });

        setApisMenuData(apiData);
        dispatch(setApiMenu(apiData));
      }
    });
  }, [pathName]);

  return (
    <MenuContext.Provider
      value={{
        docsMenu: docsMenuData,
        apisMenu: apisMenuData,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
const Providers = ({ children }: { children: React.ReactNode }) => {
  /* Chakra's cache provider wraps Emotion's cache provider,
    on dev server all styles are load properly, i.e. our scss styles are loaded after emotion's styles,
    but on prod build the reverse is happening, and emotion is overriding our styles.

    Passing a <meta/> element to chakra's <CacheProvider/> as `insertionPoint` prop tells Emotion where to inject it's own styles.

    Here we are prepending the <meta/> element on the <head/> so our component styles are loaded after Emotion's styles thus preventing it from overriding our styles
  */
  const [insertionPoint, setInsertionPoint] = useState<HTMLElement | undefined>(
    undefined
  );

  useEffect(() => {
    const head = document.querySelector("head");

    const emotionInsertionPoint = document.createElement("meta");
    emotionInsertionPoint.setAttribute("name", "emotion-insertion-point");
    emotionInsertionPoint.setAttribute("content", "");
    head?.prepend(emotionInsertionPoint);
    // This element becomes the top of head's child node <meta name="emotion-insertion-point" content="">
    setInsertionPoint(emotionInsertionPoint);
  }, []);

  if (process.env.NODE_ENV === "development") {
    return (
      <html lang="en">
        <body>
          <ReduxProvider store={store}>
            <UserProvider>
              <UnsavedChangesProvider>
                <CacheProvider>
                  <ChakraProvider
                    theme={AppTheme}
                    toastOptions={{
                      defaultOptions: {
                        duration: 5000,
                        render: (props: any) => <Toast {...props} />,
                      },
                    }}
                  >
                    {children}
                  </ChakraProvider>
                </CacheProvider>
              </UnsavedChangesProvider>
              <TimeoutHandler />
            </UserProvider>
          </ReduxProvider>
          <div id="appFooter" className="app-footer"></div>
          <footer id="appPortal"></footer>
        </body>
      </html>
    );
  }

  // Render this if not using dev server (static builds)
  return insertionPoint ? (
    <html lang="en">
      <body>
        <ReduxProvider store={store}>
          <UserProvider>
            <UnsavedChangesProvider>
              <CacheProvider insertionPoint={insertionPoint}>
                <ChakraProvider
                  theme={AppTheme}
                  toastOptions={{
                    defaultOptions: {
                      duration: 5000,
                      render: (props: any) => <Toast {...props} />,
                    },
                  }}
                >
                  {children}
                </ChakraProvider>
              </CacheProvider>
            </UnsavedChangesProvider>
            <TimeoutHandler />
          </UserProvider>
        </ReduxProvider>
        <div id="appFooter" className="app-footer"></div>
        <footer id="appPortal"></footer>
      </body>
    </html>
  ) : null;
};
export default Providers;
