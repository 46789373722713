import { ApiClient } from "@/api-config";
import {
  getAllOrgs,
  getCorePermissionsEndpoint,
} from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION, CONSOLE_ENDPOINTS } from "@/api-config/urls";
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  EnvStatus,
  Organization,
  OrganizationState,
  OrganizationsMap,
} from "../types";
import { getCookie } from "cookies-next";
import { ORGANIZATION_COOKIE } from "@/utils";
import { orderBy } from "lodash-es";

const orgInitialState: OrganizationState = {
  orgId: null,
  corePermissions: [],
  primaryOrgCnslPermissions: [],
  consolePermissions: [],
  organizations: {},
  envStatus: [],
  isLoading: false,
};

export const fetchConsolePermissions = createAsyncThunk(
  "org/fetchConsolePermissions",
  async (
    { envId, envType }: { envId: number | null; envType: string | null },
    { rejectWithValue }
  ) => {
    try {
      let params: string = "";
      // if no env/proj is selected params is empty
      if (envType === "proj") {
        params = `?projID=${envId}`;
      } else if (envType === "env") {
        params = `?envID=${envId}`;
      }

      const apiConsole = new ApiClient({
        baseUrl: CONSOLE_ENDPOINTS.myCnslPermissions,
      });
      return await apiConsole.get(params);
    } catch (err) {
      rejectWithValue(null);
    }
  }
);

export const fetchCorePermissions = createAsyncThunk(
  "org/fetchCorePermissions",
  async (_, { rejectWithValue }) => {
    try {
      const apiCore = new ApiClient({ baseUrl: getCorePermissionsEndpoint() });
      return await apiCore.get(`${API_ROUTE_CONFIGURATION.permissions}`);
    } catch (err) {
      rejectWithValue(null);
    }
  }
);

export const fetchEnvStatus = createAsyncThunk(
  "org/fetchEnvStatus",
  async () => {
    try {
      const api = new ApiClient({ baseUrl: CONSOLE_ENDPOINTS.envStatus });
      return await api.get();
    } catch (err) {
      console.error(err);
    }
  }
);

export const fetchAllOrgs = createAsyncThunk("org/fetchAllOrgs", async () => {
  try {
    return await getAllOrgs();
  } catch (err) {
    console.error(err);
  }
});

const orgSlice = createSlice({
  name: "org",
  initialState: orgInitialState,
  reducers: {
    setOrgId: (state, action: PayloadAction<number>) => {
      state.orgId = action.payload;
    },
    setCorePermissions: (state, action: PayloadAction<string[]>) => {
      state.corePermissions = action.payload;
    },
    setConsolePermissions: (
      state,
      action: PayloadAction<Record<string, string[]>>
    ) => {
      state.consolePermissions = state.orgId ? action.payload[state.orgId] : [];
    },
    setOrganizations: (state, action: PayloadAction<OrganizationsMap>) => {
      state.organizations = action.payload;
    },
    setEnvStatus: (state, action: PayloadAction<EnvStatus[]>) => {
      state.envStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Console permissions
      .addCase(fetchConsolePermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchConsolePermissions.fulfilled, (state, action) => {
        const consolePermissions = action.payload!;
        if (consolePermissions) {
          const consolePermission = Object.fromEntries(
            Object.entries(consolePermissions).map(
              ([orgId, consoleOperations]) => {
                return [
                  Number(orgId),
                  (consoleOperations as any).map((val: any) => val.id),
                ];
              }
            )
          );
          state.consolePermissions = state.orgId
            ? consolePermission[state.orgId]
            : [];
        }
        state.isLoading = false;
      })
      .addCase(fetchConsolePermissions.rejected, (state) => {
        state.isLoading = false;
        state.consolePermissions = [];
      })

      // Core permissions
      .addCase(fetchCorePermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCorePermissions.fulfilled, (state, action) => {
        const corePermissions = action.payload!;
        if (corePermissions) {
          state.corePermissions = Object.keys(corePermissions).filter(
            (perm) => corePermissions[perm]
          );
        }
        state.isLoading = false;
      })
      .addCase(fetchCorePermissions.rejected, (state) => {
        state.isLoading = false;
        state.corePermissions = [];
      })

      // Fetch orgs
      .addCase(fetchAllOrgs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllOrgs.fulfilled, (state, action) => {
        const allOrgs = action.payload!;
        const orgId = getCookie(ORGANIZATION_COOKIE);
        state.organizations = allOrgs;
        state.orgId = parseInt(`${orgId}`) || null;
        state.isLoading = false;
      })
      // Env Status
      .addCase(fetchEnvStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEnvStatus.fulfilled, (state, action) => {
        const envStatus = action.payload!;
        state.envStatus = envStatus.data.map((data: any) => ({
          id: data.id,
          usePlatformVersion: data.runtime?.usePlatformVersion ?? false,
          version: data.runtime?.version ?? null,
          name: data.name,
          serviceEndpoint: data.serviceEndpoint,
        }));
        state.isLoading = false;
      });
  },
});

// Org selectors
export const getOrgID = (state: RootState) => state.org.orgId;
export const getConsolePermissions = (state: RootState) =>
  state.org.consolePermissions;
export const getCorePermissions = (state: RootState) =>
  state.org.corePermissions;
export const getOrganizations = (state: RootState) => state.org.organizations;
export const getOrgsAsArray = createSelector(
  [getOrganizations],
  (orgsMap) => {
    return orderBy(Object.values(orgsMap), (x) => x.name.toLocaleLowerCase())
  }
)
export const getEnvStatus = (state: RootState) => state.org.envStatus;
export const getSelectedOrg = (state: RootState) =>
  state.org.orgId
    ? state.org.organizations[state.org.orgId]
    : ({} as Organization);

export { orgSlice };
