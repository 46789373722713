"use client";

import * as prismic from "@prismicio/client";
import { Content } from "@prismicio/client";
import {
  DocumentTypes,
  DocumentSliceTypes,
  colorSelect,
  alertIconsStatus,
} from "@/utils/constants";
import { ROUTE_PATH } from "@/route-config/route-path";

import {
  Slice,
  Document,
  Paragraph,
  HighlightedText,
  Markdown,
  ImageWidth,
  PrismicImage,
  PostmanCollection,
  PostmanFolder,
  FileDownload,
  CodeSideBySide,
  CodeWalkthrough,
  CodeWalkthroughLine,
  ChildProperty,
  VimeoEmbed,
  Course,
  Excerpt,
  CodeSnippet,
  CodeBlock,
  ImageTextBlock,
  DocumentCenter,
  IndexItem,
  NextAPIReference,
  RichText,
  LearningObjectives,
  AdditionalResources,
} from "@/models/document-models";

import axios from "axios";
import { AllDocumentTypes } from "../../prismicio-types";

export async function getDocumentData(
  prismicClient: prismic.Client<AllDocumentTypes>,
  type: any,
  uid: any
) {
  const data = await getPrismicGetByUID(prismicClient, type, uid);
  return data;
}

function documentDataForDocCenter(
  documentResponse: Content.DocumentCenterDocument
) {
  const title =
    documentResponse.data.article_main_title[0]?.type === "heading1"
      ? documentResponse.data.article_main_title[0]?.text
      : "Default Main Title";
  const articleMainTitle = title;
  const category = documentResponse.data.category;
  const metaDescription = documentResponse.data.meta_description;
  const readTime = documentResponse.data.time_to_read;
  const additionalResources = documentResponse.data.additional_resources;
  return {
    title,
    articleMainTitle,
    category,
    metaDescription,
    readTime,
    additionalResources,
  };
}

function documentDataForDocument(documentResponse: Content.DocumentDocument) {
  const title = documentResponse.data.article_main_title[0]?.text;
  const articleMainTitle = documentResponse.data.article_main_title[0]?.text;
  const relatedPatterns = documentResponse.data.related_patterns.map(
    (p: any) => p.related_pattern
  );
  return { title, articleMainTitle, relatedPatterns };
}

function documentDataForPattern(documentResponse: Content.PatternDocument) {
  const title = documentResponse.data.article_main_title[0]?.text;
  const articleMainTitle = documentResponse.data.article_main_title[0]?.text;
  return { title, articleMainTitle };
}

function documentDataForTrainingDoc(
  documentResponse: Content.TrainingDocumentDocument
) {
  const title = documentResponse.data.article_main_title[0]?.text;
  const articleMainTitle = documentResponse.data.article_main_title[0]?.text;
  return { title, articleMainTitle };
}

export function buildURL(type: string, uid: string) {
  let url = "";
  switch (type) {
    case DocumentTypes.Document:
    case DocumentTypes.DocumentCenter:
      url = `${ROUTE_PATH["DOCUMENT_DETAIL"]}/${type}/${uid}`;
      break;
    case DocumentTypes.ApiLibrary:
      url = `${ROUTE_PATH["API_LIBRARY_DETAIL"]}/${type}/${uid}`;
      break;
    case DocumentTypes.Pattern:
      url = `${ROUTE_PATH["TUTORIAL_DETAIL"]}/${uid}`;
      break;
    default:
      break;
  }
  return url;
}

export function fetchUrlForResources(linkDetails: AdditionalResources) {
  let url = "";
  switch (linkDetails.link_type) {
    case "prismic link":
      url =
        linkDetails?.prismic_link?.type === "pattern"
          ? `${ROUTE_PATH["TUTORIAL_DETAIL"]}/${linkDetails?.prismic_link?.uid}`
          : `${ROUTE_PATH["DOCUMENT_DETAIL"]}/${linkDetails?.prismic_link?.type}/${linkDetails?.prismic_link?.uid}`;
      break;
    case "academy":
      url = `${ROUTE_PATH.ACADEMY_CATALOG_COURSE}/?id=${linkDetails?.academy_course_link}`;
      break;
    default:
      if (linkDetails.external_link.link_type === "Document") {
        url = buildURL(
          linkDetails.external_link.type,
          linkDetails.external_link.uid
        );
      } else {
        url = linkDetails?.external_link?.url;
      }
      break;
  }
  return url;
}

export async function parseDocumentData(
  prismicClient: prismic.Client<AllDocumentTypes>,
  documentData: Content.AllDocumentTypes
) {
  if (
    documentData &&
    (documentData.type === DocumentTypes.Document ||
      documentData.type === DocumentTypes.DocumentCenter ||
      documentData.type === DocumentTypes.TrainingDocument ||
      documentData.type === DocumentTypes.Pattern)
  ) {
    const tempDocument: Document = {
      title: "",
      type: documentData.type,
      slices: [],
      uid: documentData.uid,
      relatedPatterns: <any>[],
      additionalResources: [],
      articleMainTitle: "",
      category: "",
      firstPublicationDate: documentData.first_publication_date,
      metaDescription: "",
      readTime: 10,
      url: documentData.url || "",
      pageIndex: [],
      tags: documentData.tags,
    };

    let typeSpecificData = {};
    let slices: any = [];
    switch (documentData.type) {
      case DocumentTypes.Document:
        typeSpecificData = documentDataForDocument(documentData);
        slices = documentData.data.body;
        break;
      case DocumentTypes.Pattern:
        typeSpecificData = documentDataForPattern(documentData);
        slices = documentData.data.body;
        break;
      case DocumentTypes.TrainingDocument:
        typeSpecificData = documentDataForTrainingDoc(documentData);
        slices = documentData.data.body;
        break;
      case DocumentTypes.DocumentCenter:
        typeSpecificData = documentDataForDocCenter(documentData);
        slices = documentData.data.slices;
        break;
      default:
        break;
    }
    tempDocument.slices = await parseDocumentSlices(prismicClient, slices);

    return { ...tempDocument, ...typeSpecificData };
  }
}

async function getCodeWalkthrough(
  prismicClient: prismic.Client<AllDocumentTypes>,
  sliceData: Content.CodeWalkthroughSlice
) {
  const codeWalkthroughLink: any = sliceData.primary.codeWalkthroughLink;
  const walkthrough = await prismicClient.getByUID(
    DocumentTypes.CodeWalkthrough,
    codeWalkthroughLink.uid
  );
  return {
    // we might want to make use of the prismicdom library to get these text values out instead of this fragile index accessing.
    language: walkthrough.data.language,
    code:
      walkthrough.data.code[0]?.type === "preformatted"
        ? walkthrough.data.code[0]?.text
        : "",
    title: walkthrough.data.title[0]?.text,
    subtitle: walkthrough.data.subtitle[0]?.text,
    codeHeading: walkthrough.data.codeheading[0]?.text,
    lines: await getWalkthroughLines(prismicClient, walkthrough.data.lines),
  } as CodeWalkthrough;
}

async function getWalkthroughLines(
  prismicClient: prismic.Client<AllDocumentTypes>,
  lineData: any
): Promise<CodeWalkthroughLine[]> {
  let lines: CodeWalkthroughLine[] = [];
  for (let i = 0; i < lineData.length; i++) {
    const line = lineData[i];
    let childProperties = [] as ChildProperty[];
    if (line.child_properties?.id) {
      const propertyData: any = await prismicClient.getByID(
        line.child_properties.id,
        {}
      );
      childProperties = await getChildProperties(
        prismicClient,
        propertyData.data?.properties
      );
    }
    lines.push({
      description: line.description,
      start: line.start,
      end: line.end,
      childProperties: childProperties,
    } as CodeWalkthroughLine);
  }
  return lines;
}

async function getChildProperties(
  prismicClient: prismic.Client<AllDocumentTypes>,
  childPropertyData: any
) {
  let childProperties: ChildProperty[] = [];
  let childChildProperties: ChildProperty[] = [];

  for (const ca of childPropertyData) {
    if (ca.child_child_property_group?.id) {
      const propertyData: any = await prismicClient.getByID(
        ca.child_child_property_group.id,
        {}
      );
      childChildProperties = await getChildProperties(
        prismicClient,
        propertyData.data?.properties
      );
    } else {
      childChildProperties = [];
    }
    childProperties.push({
      description: ca.description,
      child_child_property_group: childChildProperties,
    } as ChildProperty);
  }
  return childProperties;
}

function getVimeoEmbed(sliceData: Content.VimeoEmbedSlice): VimeoEmbed {
  return {
    src: sliceData.primary.source,
    title: sliceData.primary.title[0]?.text,
    description:
      sliceData.primary.description[0]?.type === "preformatted" || 
      sliceData.primary.description[0]?.type === "paragraph"
        ? sliceData.primary.description[0]?.text
        : "",
  } as VimeoEmbed;
}

function getCourse(sliceData: Content.CourseSlice): Course {
  return {
    desc: sliceData.primary.desc,
    courseId: sliceData.primary.course_id,
  } as Course;
}

async function getExcerpt(
  prismicClient: prismic.Client<AllDocumentTypes>,
  sliceData: Content.ExcerptSlice
): Promise<Excerpt> {
  const { type, uid }: any = sliceData.primary.excerptLink;
  const data = await getPrismicGetByUID(prismicClient, type, uid);
  const document = (await parseDocumentData(prismicClient, data)) as Document;
  return {
    document,
  };
}
export async function getPrismicGetByUID(
  prismicClient: prismic.Client<AllDocumentTypes>,
  type: any,
  uid: string,
  params?: Partial<prismic.BuildQueryURLArgs>
): Promise<any> {
  try {
    return params
      ? await prismicClient.getByUID(type, uid, params)
      : await prismicClient.getByUID(type, uid);
  } catch {
    return null;
  }
}

export async function getPrismicGetAllByType(
  prismicClient: prismic.Client<AllDocumentTypes>,
  type: any,
  params?: Partial<prismic.BuildQueryURLArgs>
): Promise<any> {
  try {
    return params
      ? await prismicClient.getAllByType(type, params)
      : await prismicClient.getAllByType(type);
  } catch {
    return null;
  }
}

async function getExample(
  prismicClient: prismic.Client<AllDocumentTypes>,
  sliceData: Content.ExampleSlice
): Promise<Excerpt> {
  const { type, uid }: any = sliceData.primary.examplelink;
  const data = await prismicClient.getByUID(type, uid);
  const document = (await parseDocumentData(prismicClient, data)) as Document;
  return {
    document,
  };
}

function getImageTextBlock(
  sliceData: Content.ImageTextBlockSlice
): ImageTextBlock {
  return {
    title: sliceData.primary.image_block_title[0]?.text,
    text: sliceData.primary.image_block_text,
    image: getPrismicImage(sliceData.primary.image_block_image),
  } as ImageTextBlock;
}

function getCodeBlock(sliceData: Content.CodeBlockSlice): CodeBlock {
  return {
    title: sliceData.primary.title[0]?.text || "",
    code:
      sliceData.primary.code[0]?.type === "preformatted"
        ? sliceData.primary.code[0]?.text
        : "",
  } as CodeBlock;
}

function getCodeSnippet(sliceData: Content.CodeSnippetSlice): CodeSnippet {
  return {
    language: sliceData.primary.language,
    code:
      sliceData.primary.code[0]?.type === "preformatted"
        ? sliceData.primary.code[0]?.text
        : "",
    title: sliceData.primary.title[0]?.text,
    subtitle: sliceData.primary.subtitle[0]?.text,
    codeHeading: sliceData.primary.codeheading[0]?.text,
    description: sliceData.primary.description,
    markdownDescription:
      sliceData.primary.markdown_description[0]?.type === "preformatted"
        ? sliceData.primary.markdown_description[0]?.text
        : "",
  } as CodeSnippet;
}

function getParagraph(
  sliceData: Content.ParagraphSlice | Content.ParagraphlegacySlice
): Paragraph {
  return {
    title: sliceData.primary?.text_block_title,
    text: sliceData.primary?.text_block_paragraph,
  } as Paragraph;
}

function getRichText(sliceData: Content.RichTextSlice): RichText {
  return {
    content: sliceData.primary.content,
  };
}

function getHighlightedText(
  sliceData: Content.HighlightedTextLegacySlice
): HighlightedText {
  return {
    text: sliceData.primary.highlighted_text,
    color: colorSelect.dict[sliceData.primary.color],
    iconStatus: alertIconsStatus.dict[sliceData.primary.color],
  } as HighlightedText;
}

function getMarkdown(sliceData: Content.MarkdownSlice): Markdown {
  return {
    content:
      sliceData.primary.code[0]?.type === "preformatted"
        ? sliceData.primary.code[0].text
        : "",
  } as Markdown;
}

function getImageWidth(sliceData: Content.ImageWidthSlice): ImageWidth {
  return {
    width: sliceData.primary.width,
    image: getPrismicImage(sliceData.primary.image),
  } as ImageWidth;
}

function getPrismicImage(imageData: prismic.ImageField): PrismicImage {
  return {
    alt: imageData.alt || "Image",
    src: imageData.url,
  } as PrismicImage;
}

async function getPostmanCollection(
  sliceData: Content.PostmanCollectionSlice
): Promise<PostmanCollection> {
  let collection: PostmanCollection = {
    download: await getFileDownload(sliceData.primary.postman_collection),
    items: [],
    title: "",
    description: "",
  };
  let responseData: any;
  await axios.get(collection.download.location).then((response) => {
    responseData = response.data;
  });

  collection.items = await getPostmanTree(responseData.item);
  collection.title = responseData.info.name;
  collection.description = responseData.info.description;

  return collection;
}

async function getPostmanTree(postmanItems: any): Promise<PostmanFolder[]> {
  let postmanTree: PostmanFolder[] = [];
  for (const item of postmanItems) {
    postmanTree.push(await createPostmanFolder(item));
  }
  return postmanTree;
}

async function createPostmanFolder(postmanData: any): Promise<PostmanFolder> {
  return {
    name: postmanData.name,
    request: postmanData.request ? postmanData.request : null,
    resposne: postmanData.response ? postmanData.response : null,
    children: postmanData.item ? await getPostmanTree(postmanData.item) : null,
    showChildren: false,
  } as PostmanFolder;
}

async function getFileDownload(fileDownload: any): Promise<FileDownload> {
  return {
    name: fileDownload.name,
    location: fileDownload.url,
  } as FileDownload;
}

async function getCodeSideBySide(
  sideBySideData: Content.CodeSideBySideSlice
): Promise<CodeSideBySide> {
  return {
    leftCode:
      sideBySideData.primary.left_code[0]?.type === "preformatted"
        ? sideBySideData.primary.left_code[0]?.text
        : "",
    rightCode:
      sideBySideData.primary.right_code[0]?.type === "preformatted"
        ? sideBySideData.primary.right_code[0]?.text
        : "",
  } as CodeSideBySide;
}

function getDocumentCenterTopic(
  docCenterTopic: Content.DocCenterTopicSlice
): DocumentCenter {
  return {
    id: docCenterTopic.id,
    items: docCenterTopic.items,
    primary: docCenterTopic.primary,
  };
}

async function getApiAdditionalInformation(
  prismicClient: prismic.Client<AllDocumentTypes>,
  data: any
) {
  const itemsDocData = [];
  for (const item of data.items) {
    const docData: any = await getDocumentData(
      prismicClient,
      item.links.type,
      item.links.uid
    );
    if (docData)
      itemsDocData.push({
        title: docData.data.article_main_title[0].text,
        uid: item.links.uid,
        type: item.links.type,
      });
  }

  return {
    title: data.primary.title,
    subtitle: data.primary.additionalinforich,
    items: itemsDocData,
  };
}

function getNextApiReference(data: Content.NextApiReferenceSlice) {
  return {
    title: data.primary.title,
    items: data.items,
  } as NextAPIReference;
}

function getLearningObjectives(data: Content.LearningObjectivesSlice) {
  return {
    title: data.primary.title,
    content: data.primary.content,
  } as LearningObjectives;
}

function getCodeSnippetHeader(data: Content.CodeSnippetHeaderSlice) {
  return {
    title: data.primary.title,
    description: data.primary.description,
    code:
      data.primary.code[0]?.type === "preformatted"
        ? data.primary.code[0]?.text
        : "",
    codeHeading: data.primary.codeheading,
    language: (data.primary.language || "Json").toUpperCase(),
  } as CodeSnippet;
}

function getAdditionalResources(data: Content.ResourcesLinksSectionSlice) {
  return data.items as Content.ResourcesLinksSectionSliceDefaultItem[];
}

export async function parseDocumentSlices(
  prismicClient: prismic.Client<AllDocumentTypes>,
  slicesData: any
) {
  let slices: any = [];
  for (const sliceData of slicesData) {
    let slice: Slice = {
      type: sliceData.slice_type,
      data: "",
    };
    switch (sliceData.slice_type) {
      case DocumentSliceTypes.Paragraph:
      case DocumentSliceTypes.ParagraphLegacy:
      case DocumentSliceTypes.SmallCaseParagraph:
        slice.data = getParagraph(sliceData);
        break;
      case DocumentSliceTypes.Markdown:
        slice.data = getMarkdown(sliceData);
        break;
      case DocumentSliceTypes.Example:
        slice.data = await getExample(prismicClient, sliceData);
        break;
      case DocumentSliceTypes.ImageWidth:
        slice.data = getImageWidth(sliceData);
        break;
      case DocumentSliceTypes.CodeBlock:
        slice.data = getCodeBlock(sliceData);
        break;
      case DocumentSliceTypes.CodeSnippet:
        slice.data = getCodeSnippet(sliceData);
        break;
      case DocumentSliceTypes.ImageTextBlock:
        slice.data = getImageTextBlock(sliceData);
        break;
      case DocumentSliceTypes.Excerpt:
        slice.data = await getExcerpt(prismicClient, sliceData);
        break;
      case DocumentSliceTypes.VimeoEmbed:
        slice.data = getVimeoEmbed(sliceData);
        break;
      case DocumentSliceTypes.VimeoEmbed2:
        slice.data = getVimeoEmbed(sliceData);
        break;
      case DocumentSliceTypes.CodeWalkthrough:
        slice.data = await getCodeWalkthrough(prismicClient, sliceData);
        break;
      case DocumentSliceTypes.HighlightedText:
      case DocumentSliceTypes.HighlightedTextLegacy:
        slice.data = await getHighlightedText(sliceData);
        break;
      case DocumentSliceTypes.PostmanCollection:
        slice.data = await getPostmanCollection(sliceData);
        break;
      case DocumentSliceTypes.FileDownload:
        slice.data = await getFileDownload(sliceData.primary.file);
        break;
      case DocumentSliceTypes.CodeSideBySide:
        slice.data = await getCodeSideBySide(sliceData);
        break;
      case DocumentSliceTypes.Course:
        slice.data = await getCourse(sliceData);
        break;
      case DocumentSliceTypes.DocCenterTopic:
        slice.data = getDocumentCenterTopic(sliceData);
        break;
      case "api_additional_information":
        slice.data = await getApiAdditionalInformation(
          prismicClient,
          sliceData
        );
        break;
      case "next_api_reference":
        slice.data = getNextApiReference(sliceData);
        break;
      case "code_snippet_header":
        slice.data = getCodeSnippetHeader(sliceData);
        break;
      case "rich_text":
        slice.data = getRichText(sliceData);
        break;
      case "learning_objectives":
        slice.data = getLearningObjectives(sliceData);
        break;
      case DocumentSliceTypes.AdditionalResources:
        slice.data = getAdditionalResources(sliceData);
      default:
        break;
    }
    slices.push(slice);
  }
  return slices;
}
