"use client";
import React, { useRef, useState, useContext, useEffect } from "react";
import { usePathname } from "next/navigation";
import "./Flyout.scss";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Box,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Portal,
  MenuItem,
  MenuButton,
  Menu,
  MenuList,
} from "@chakra-ui/react";
import Link from "next/link";
import {
  apiIcon,
  arrowForwardIcon,
  copyIcon,
  downloadIcon,
  resourcesIcon,
} from "public/assets";
import Image from "next/image";
import AcademyCourseData from "./academy-course-data/AcademyCourseData";
import copy from "copy-to-clipboard";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { GeneralizedTooltip } from "../";
import { DocLinksType } from "@/models/global-models";
import { ROUTE_PATH } from "@/route-config/route-path";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { getALMAccessToken } from "@/client-api-manager/alm-api";

type FlyoutProps = {
  placement: "right" | "left" | "top" | "bottom";
  children: React.ReactNode;
  slug?: string;
};

type VideoSelected = {
  courseId: string;
  moduleId: string;
};

export default function Flyout({ placement, children, slug }: FlyoutProps) {
  const {
    isOpen: isFlyoutOpen,
    onOpen: onFlyoutOpen,
    onClose: onFlyoutClose,
  } = useDisclosure({ defaultIsOpen: true });
  const [flyoutType, setFlyoutType] = useState<"resource" | "api">("resource");
  const accessToken = getALMAccessToken();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const refFlyout = useRef<HTMLDivElement | null>(null);
  const [showVideos, setShowVideos] = useState<boolean>(false);
  const [videoSelected, setVideoSelected] = useState<VideoSelected>();
  const flyoutRef = useRef<any>();
  const [isProd, setIsProd] = useState(false);

  const { tooltipFlyoutDetails, currDocsFlyoutData, setCurrDocsFlyoutData } =
    useContext<ProductConfigurationContextType>(productConfigurationContext);

  async function getDocsData() {
    const docsData = tooltipFlyoutDetails?.docsLinks;
    let docsLinkData: DocLinksType[] = [];
    docsData &&
      docsData?.map((item) => {
        Promise.all([item]).then((values) => {
          if (values?.length) {
            docsLinkData.push(values[0]);
            setCurrDocsFlyoutData?.(docsLinkData);
          }
        });
      });
  }
  useEffect(() => {
    getDocsData();
  }, [tooltipFlyoutDetails?.docsLinks]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const hostname = window.location.hostname;
      if (hostname === "finxact.io") {
        setIsProd(true);
      } else {
        setIsProd(false);
      }
    }
  }, []);

  const jsonDownloadHandler = (jsonData: string, fileName: string) => {
    const jsonString = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const jsonCopyToClipboardHandler = (jsonData: string) => {
    copy(jsonData);
  };

  const openVideos = (courseId: string, moduleId: string) => {
    setShowVideos(true);
    setVideoSelected({
      courseId,
      moduleId,
    });
  };
  const closeVideos = () => setShowVideos(false);

  const resetDrawer = () => {
    setFlyoutType("resource");
    setTabIndex(0);
    closeVideos();
    onFlyoutClose();
  };

  const openFlyout = (type: "api" | "resource") => {
    setFlyoutType(type);
    onFlyoutOpen();
  };

  return (
    <div>
      <div className={`${isFlyoutOpen ? "display-none" : "flyout-container"}`}>
        <div
          className="flyout"
          onClick={() => openFlyout("resource")}
          ref={refFlyout}
        >
          <Image
            src={resourcesIcon}
            alt="Resources Icon"
            className="image-styling-flyout"
          />
          <p className="image-title-styling-flyout">Resource</p>
        </div>
        <div
          className="flyout"
          onClick={() => openFlyout("api")}
          ref={refFlyout}
        >
          <Image
            src={apiIcon}
            alt="API icon"
            className="image-styling-flyout"
          />
          <p className="image-title-styling-flyout">APIs</p>
        </div>
      </div>
      <Box className="portal-container-width-auto" ref={flyoutRef}></Box>
      <Portal containerRef={flyoutRef}>
        <Drawer
          placement={placement}
          onClose={resetDrawer}
          isOpen={isFlyoutOpen}
          finalFocusRef={refFlyout}
          blockScrollOnMount={false}
          closeOnOverlayClick={false}
          trapFocus={false}
        >
          <DrawerContent
            className={`drawer-content-flyout ${flyoutType === "api" ? "api-flyout-container" : ""}`}
          >
            {!showVideos && (
              <>
                <DrawerCloseButton
                  size="lg"
                  position="absolute"
                  top="0"
                  right="0"
                />
                <DrawerHeader padding={0}>
                  <Menu>
                    {({ isOpen }) => (
                      <>
                        <MenuButton
                          className="flyout-header"
                          isActive={isOpen}
                          as={Button}
                          rightIcon={<ChevronDownIcon />}
                        >
                          {flyoutType === "resource" && "Resources"}
                          {flyoutType === "api" && "APIs"}
                        </MenuButton>
                        <MenuList className="menu-list-container">
                          <MenuItem
                            className={`dropdown-menu-item ${flyoutType === "resource" ? "selected" : ""}`}
                            onClick={() => setFlyoutType("resource")}
                          >
                            Resources
                          </MenuItem>
                          <MenuItem
                            className={`dropdown-menu-item ${flyoutType === "api" ? "selected" : ""}`}
                            onClick={() => setFlyoutType("api")}
                          >
                            APIs
                          </MenuItem>
                        </MenuList>
                      </>
                    )}
                  </Menu>
                  <Text className="flyout-subheader">
                    {flyoutType === "resource" &&
                      "Find resources to support this configuration"}
                    {flyoutType === "api" &&
                      "Relevant APIs for this configuration for your easy access"}
                  </Text>
                </DrawerHeader>
                <DrawerBody padding={0}>
                  {flyoutType === "resource" && (
                    <Tabs index={tabIndex} isManual isFitted>
                      <TabList>
                        <Tab onClick={() => setTabIndex(0)}>Docs</Tab>
                        <Tab onClick={() => setTabIndex(1)}>Videos</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <Box className="doc-link-tab" pt={8}>
                            <Text className="docs-tab-subhead">
                              Step-by-step guidance
                            </Text>
                            <Flex direction="column">
                              {tooltipFlyoutDetails?.docsLinks?.length
                                ? currDocsFlyoutData?.map((doc: any) => (
                                    <Box
                                      className="doc-list-card"
                                      key={doc.uid}
                                    >
                                      <Box className="doc-list-card-top">
                                        <Text>{doc.title}</Text>
                                        <Flex className="doc-card-end">
                                          {doc.time && (
                                            <Text>{doc.time}mins</Text>
                                          )}
                                          <Link
                                            className="arrow-link"
                                            href={`${ROUTE_PATH["DOCUMENT_DETAIL"]}/${doc.type}/${doc.uid}`}
                                            target="_blank"
                                          >
                                            <Image
                                              className="arrow-img"
                                              src={arrowForwardIcon}
                                              alt="arrow forward"
                                            />
                                          </Link>
                                        </Flex>
                                      </Box>
                                      <Text className="doc-card-caption">
                                        {doc.caption}
                                      </Text>
                                    </Box>
                                  ))
                                : "No Content Available"}
                            </Flex>
                            <Button className="app-btn-link doc-bottom-link">
                              <Link
                                target="_blank"
                                href={`${ROUTE_BASE_URL["DOCUMENT_CENTER"]}`}
                              >
                                Visit Docs for a full list of resources
                              </Link>
                            </Button>
                          </Box>
                        </TabPanel>

                        <TabPanel>
                          <Box className="videos-tab" pt={8}>
                            {!accessToken ? (
                              <p>
                                Unable to fetch videos. Please check back soon.
                              </p>
                            ) : (
                              <>
                                <Text className="docs-tab-subhead">
                                  Step-by-step guidance
                                </Text>
                                {tooltipFlyoutDetails?.learningLinks?.length
                                  ? tooltipFlyoutDetails.learningLinks.map(
                                      (video: any, idx: number) => (
                                        <Box
                                          className="doc-list-card"
                                          key={idx}
                                        >
                                          <Box className="doc-list-card-top">
                                            <Text>{video.courseName}</Text>
                                            <Flex className="doc-card-end">
                                              {video.time && (
                                                <Text>
                                                  {videoSelected?.moduleId} mins
                                                </Text>
                                              )}
                                              <Link
                                                className="arrow-link"
                                                href=""
                                                onClick={() =>
                                                  openVideos(
                                                    isProd
                                                      ? video.courseIdProd
                                                      : video.courseId,
                                                    video.moduleId
                                                  )
                                                }
                                              >
                                                <Image
                                                  className="arrow-img"
                                                  src={arrowForwardIcon}
                                                  alt="arrow forward"
                                                />
                                              </Link>
                                            </Flex>
                                          </Box>
                                          <Text className="doc-card-caption">
                                            {video.caption}
                                          </Text>
                                        </Box>
                                      )
                                    )
                                  : "No Content Available"}
                              </>
                            )}
                          </Box>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  )}
                  {flyoutType === "api" && (
                    <>
                      {tooltipFlyoutDetails?.apiLinks?.apiCollection?.url && (
                        <Link
                          href={
                            tooltipFlyoutDetails?.apiLinks?.apiCollection?.url
                          }
                        >
                          <Button className="app-btn-reg-secondary-transparent">
                            Download API collection
                          </Button>
                        </Link>
                      )}
                      <h6 className="api-list">API</h6>
                      {tooltipFlyoutDetails?.apiLinks?.apiDetails?.length ? (
                        tooltipFlyoutDetails.apiLinks?.apiDetails?.map(
                          (api: any, index: number) => (
                            <Accordion allowMultiple key={index}>
                              <AccordionItem className="fx-accordion">
                                <Box className="api-top">
                                  <div className="api-title">
                                    <AccordionButton
                                      className="api-container"
                                      aria-label="accordion-icon"
                                    >
                                      <AccordionIcon className="fx-accordion-icon" />
                                    </AccordionButton>
                                    <Link
                                      href={`${ROUTE_PATH["API_LIBRARY_DETAIL"]}/api_library/${api.uid}`}
                                      target="_blank"
                                    >
                                      <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        className="api-name"
                                      >
                                        {api.title}
                                      </Box>
                                    </Link>
                                  </div>
                                  <Box className="api-card-end">
                                    <GeneralizedTooltip
                                      placement="top"
                                      content="Copy"
                                    >
                                      <Button
                                        className="api-card-buttons"
                                        onClick={() => {
                                          jsonCopyToClipboardHandler(
                                            api.jsonData
                                          );
                                        }}
                                      >
                                        <Image
                                          src={copyIcon}
                                          alt="copy content"
                                        />
                                      </Button>
                                    </GeneralizedTooltip>

                                    <GeneralizedTooltip
                                      placement="top"
                                      content="Download"
                                    >
                                      <Button
                                        className="api-card-buttons"
                                        mr={1}
                                        onClick={() => {
                                          jsonDownloadHandler(
                                            api.jsonData,
                                            api.title + ".json"
                                          );
                                        }}
                                      >
                                        <Image
                                          src={downloadIcon}
                                          alt="download content"
                                        />
                                      </Button>
                                    </GeneralizedTooltip>
                                  </Box>
                                </Box>
                                <AccordionPanel
                                  pb={4}
                                  className="accordian-description"
                                >
                                  {api.description}
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          )
                        )
                      ) : (
                        <Box ml={4} mt={4.8}>
                          No Content Available
                        </Box>
                      )}
                    </>
                  )}
                </DrawerBody>
              </>
            )}

            {showVideos && (
              <AcademyCourseData
                back={closeVideos}
                courseId={videoSelected?.courseId}
              />
            )}
          </DrawerContent>
        </Drawer>
      </Portal>
    </div>
  );
}
