"use client";

import { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { FinxactFeedbackFormModal } from "@/components/common";

import { getEnvId, getEnvStatus, getSelectedOrg } from "@/store";
import { EXPLORE_FINXACT_STEPS_NAME, ORG_PHASE } from "@/utils";

import { getConsolePermissions } from "@/api-config/api-service";
import { TicketedRequestInterface } from "@/components/dashboard/Dashboard";
import { ApiClient, API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  getAllWorkflowGeneric,
  DashboardWorkflowResponse,
} from "@/api-config/api-service";
import { CONSOLE_ENDPOINTS } from "@/api-config/urls";

export default function BannerNotification() {
  const appInputModalRef = useRef<any>();

  const [step, setStep] = useState<string>("");
  const [modalTitle, setModalTitle] = useState<string>("");

  const currentOrg = useSelector(getSelectedOrg);
  const isProspect = currentOrg?.phase === ORG_PHASE.prospect;
  const envId = useSelector(getEnvId);
  const envData = useSelector(getEnvStatus);

  const consolePermissions = useSelector(getConsolePermissions);
  const isEnvDown = useMemo(() => {
    const currectEnv = envData?.filter((data) => data.id === envId);
    //If version is null means env is down
    return currectEnv.some((data) => data.version === null);
  }, [envData, envId]);

  useEffect(() => {
    const getData = async () => {
      const api = new ApiClient({ baseUrl: CONSOLE_ENDPOINTS.ticketRequests });
      const [reqData, data] = await Promise.all([
        api.get() as Promise<TicketedRequestInterface[]>,
        getAllWorkflowGeneric(API_ROUTE_CONFIGURATION.dashboard) as Promise<
          DashboardWorkflowResponse[]
        >,
      ]).then((data) => data);
    };

    getData();
  }, [envData, consolePermissions, currentOrg, isEnvDown]);

  const title = envData.length ? "Sandbox" : "Preview";
  const language = envData.length
    ? "You are in sandbox mode. Contact Finxact to upgrade the full capabilities."
    : "You are viewing a preview of Dashboard. Request a sandbox to get started.";

  function showModal(name: string, title: string, e: any) {
    appInputModalRef?.current?.openModal();
    e.stopPropagation();
    setStep(name);
    setModalTitle(title);
  }

  const reqSandbox = (
    <a
      onClick={showModal.bind(null, EXPLORE_FINXACT_STEPS_NAME.sandboxOverview, "Request a sandbox experience")}
    >
      Request a sandbox experience
    </a>
  );

  const speakWithFinxact = (
    <a
      onClick={showModal.bind(
        null,
        EXPLORE_FINXACT_STEPS_NAME.enterpriseLicense,
        "Speak with Finxact"
      )}
    >
      Speak with Finxact
    </a>
  );
  return (
    <>
      {isProspect && (
        <Box className="banner">
          <h6>{title}</h6>
          <p>{language}</p>
          <Button className="app-btn-link">
            {envData.length ? speakWithFinxact : reqSandbox}
          </Button>
        </Box>
      )}
      {/* Open a modal when user clicks on "Request a sandbox experience" or "Speak with finxact" */}
      <FinxactFeedbackFormModal
        requestType={step}
        modalRef={appInputModalRef}
        title={modalTitle}
      />
    </>
  );
}
