import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Menu } from "../types";

// Initial state with typed structure
const initialState: Menu = {
  docsMenu: [],
  apiMenu: [],
};

// Create a slice with typed actions and state
const menuSlice = createSlice({
  name: "menu",
  initialState: initialState,
  reducers: {
    setDocsMenu: (state, action: PayloadAction<any>) => {
      state.docsMenu = action.payload;
    },
    setApiMenu: (state, action: PayloadAction<any>) => {
      state.apiMenu = action.payload;
    },
  },
});

export const getDocsMenu = (state: RootState) => state.menu.docsMenu;
export const getApiMenu = (state: RootState) => state.menu.apiMenu;

export { menuSlice };
