import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import { Form, Widgets } from "@rjsf/chakra-ui";
import { FormProps } from "@rjsf/core";
import { RJSFSchema, FieldProps, WidgetProps, UiSchema } from "@rjsf/utils";
import { Select } from "chakra-react-select";
import { cloneDeep, get, isObject, pull } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
import { customizeValidator } from "@rjsf/validator-ajv8";
import "./RJSForm.scss";
import { ApiClient } from "@/api-config";
import { getCoreBaseEndpoint } from "@/api-config/api-service";

const FXTMultiSelectField = function (props: FieldProps) {
  return (
    <FXTSelectWidget
      schema={props.schema}
      uiSchema={{
        "ui:options": props.uiSchema?.options,
        ...props.uiSchema,
      }}
      formContext={props.formContext}
      required={props.required}
      isMulti
      onChange={props.onChange}
    />
  );
};

const FXTSelectWidget = function (
  props: Partial<WidgetProps> & {
    schema?: RJSFSchema & {
      items?: {
        enum?: any[];
        enumNames?: string[];
      };
    };
  }
) {
  const [unfilteredData, setUnfiltedData] = useState<
    null | Record<string, unknown>[][]
  >(null);
  const [filteredOptions, setFilteredOptions] = useState<
    { label: string; value: any }[]
  >([]);

  const isItemIncluded = useCallback(
    (
      item: Record<string, unknown>,
      formData: any,
      filters?: { object: string; field: string }[]
    ) => {
      if (filters) {
        for (var filter of filters) {
          if (get(item, filter.object) !== get(formData, filter.field)) {
            return false;
          }
        }
      }

      return true;
    },
    []
  );

  useEffect(() => {
    const f = async () => {
      const newFilteredOptions: { label: string; value: any }[] = [];
      if (props.uiSchema && props.uiSchema["ui:options"]) {
        const uiOptions = props.uiSchema
          ? props.uiSchema["ui:options"]
          : undefined;
        if (!unfilteredData) {
          const apiClient = new ApiClient({
            baseUrl: getCoreBaseEndpoint(),
          });

          if (uiOptions?.apis && Array.isArray(uiOptions?.apis)) {
            const promises = [];
            for (var api of uiOptions.apis) {
              promises.push(
                apiClient.get(
                  `${api.api}${api.where ? `?filter.q=${api.where}` : ""}`
                )
              );
            }

            const results = await Promise.all(promises);
            setUnfiltedData(
              results.map((result, index) => {
                if (
                  uiOptions?.apis &&
                  Array.isArray(uiOptions?.apis) &&
                  uiOptions.apis[index].responsePath
                ) {
                  return result[uiOptions.apis[index].responsePath];
                } else {
                  return result.data;
                }
              })
            );
          }
        }

        const existingKeys = {} as Record<string, boolean>;
        for (const [apiIndex, data] of unfilteredData?.entries() ?? []) {
          data.forEach((item: Record<string, any>) => {
            if (uiOptions?.apis && Array.isArray(uiOptions?.apis)) {
              const api = uiOptions.apis[apiIndex];

              if (
                isItemIncluded(
                  item,
                  props.formContext.formData,
                  Array.isArray(uiOptions.filters)
                    ? uiOptions.filters
                    : undefined
                ) &&
                item[api.keyObject] &&
                item[api.valObject] &&
                (!api.distinct || !existingKeys[`${item[api.keyObject]}`])
              ) {
                newFilteredOptions.push({
                  label: uiOptions?.includeKeyInDesc
                    ? item[api.keyObject] + ` - ${item[api.valObject]}`
                    : `${item[api.valObject]}`,
                  value: item[api.keyObject],
                });

                // if checking for distinct and to keys map
                if (api.distinct) {
                  existingKeys[`${item[api.keyObject]}`] = true;
                }
              }
            }
          });
        }
      } else {
        // Get from schema
        let enumKeys = props.schema?.enum ?? [];
        let enumNames = props.schema?.enumNames ?? [];

        if (props.isMulti && props.schema?.items) {
          enumKeys = props.schema?.items?.enum ?? [];
          enumNames = props.schema?.items?.enumNames ?? [];
        }

        for (const [enumIndex, enumKey] of enumKeys.entries()) {
          newFilteredOptions.push({
            label: enumNames[enumIndex],
            value: enumKey,
          });
        }
      }
      setFilteredOptions(newFilteredOptions);
    };
    f();
  }, [
    props.uiSchema,
    props.isMulti,
    props.schema,
    props.formContext?.formData,
    unfilteredData,
    isItemIncluded,
  ]);

  const onChange = (value: any) => {
    if (props.onChange) {
      if (Array.isArray(value)) {
        props.onChange(value.map((item) => item.value));
      } else {
        props.onChange(value?.value);
      }
    }
  };

  return (
    <FormControl isRequired={props.required}>
      <FormLabel>{props.schema?.title}</FormLabel>
      <Select
        onChange={onChange}
        options={filteredOptions ?? []}
        size="lg"
        isMulti={props.isMulti}
        menuPortalTarget={document.body}
        chakraStyles={{
          placeholder: (css) => ({ ...css, fontSize: 15 }),
          control: (css) => ({ ...css, fontSize: 15, height: '40px'}),
        }}
        classNames={{
          menuPortal: () => "fxt-select-menu-portal",
        }}
      />
    </FormControl>
  );
};

const FXTPatternPropField = function () {
  // Hiding PatternProperties until we can create a full features FXTPatternPropField in CNSL-5563
  return <></>;
};

// eslint-disable-next-line react/display-name
const RjsfForm = <T,>(
  props: Omit<FormProps<T, RJSFSchema>, "validator"> & {
    unRequire?: string | string[];
    initialFormData?: Record<string, unknown>;
  }
) => {
  const { initialFormData, schema, uiSchema, onChange, ...everythingElse } =
    props;
  const [componentFormData, setComponentFormData] = useState(initialFormData);
  const validator = customizeValidator({
    ajvFormatOptions: [
      "date", // list of formats that we will have ajv validate. Right now removing duration
      "time",
      "date-time",
      "uri",
      "uri-reference",
      "uri-template",
      "email",
      "hostname",
      "ipv4",
      "ipv6",
      "ipv6",
      "uuid",
      "json-pointer",
      "relative-json-pointer",
    ],
  });
  const removableFrameworkFields = useMemo(
    () =>
      new Set([
        "_action",
        "_asOfDtm",
        "_attch",
        "_cLog",
        "_schVn",
        "_uLog",
        "_vn",
        "_cLogRef",
        "_flags",
        "_uLogRef",
        "_config",
      ]),
    []
  );

  const getProcessedSchema = useMemo(() => {
    if (schema) {
      const schemaClone = cloneDeep(schema);

      // Remove _action elements
      for (const toRm of removableFrameworkFields) {
        if (schemaClone.properties?.[toRm]) {
          delete schemaClone.properties[toRm];
        }
        if (schemaClone.required?.includes(toRm)) {
          pull(schemaClone.required, toRm);
        }

        if (schemaClone.definitions) {
          for (const [key, value] of Object.entries(schemaClone.definitions)) {
            if (
              isObject(value) &&
              isObject(value.properties) &&
              value.properties[toRm]
            ) {
              delete (schemaClone.definitions[key] as any).properties[toRm];
            }
            if (
              value &&
              typeof value === "object" &&
              Array.isArray(value.required) &&
              value.required.includes(toRm)
            ) {
              pull(value.required, toRm);
            }
          }
        }
      }

      // When fields are marked unrequired in FXTForm props
      if (props.unRequire && schema && schemaClone.properties) {
        let unRequired = [];
        if (Array.isArray(props.unRequire)) {
          unRequired = props.unRequire;
        } else {
          unRequired = props.unRequire.split(",");
        }

        for (let toRm of unRequired) {
          toRm = toRm ? toRm.trim() : "";

          // If lowercase, it is a direct schema property
          if (toRm && toRm.charAt(0) === toRm.charAt(0).toLowerCase()) {
            pull(schemaClone.required ?? [], toRm);

            // If uppercase, it is part of a sub-schema
          } else if (toRm && toRm.charAt(0) === toRm.charAt(0).toUpperCase()) {
            const brokenPath = toRm.split(".");
            const schemaName = brokenPath[0];
            const schemaPath = brokenPath.slice(1).join(".");

            if (schemaPath) {
              const x: any = get(
                schemaClone,
                `definitions.${schemaName}.required`
              );
              pull(x, schemaPath);
            } else {
              // If no schema path is provided, then don't require any fields on the sub schema
              const definitionSchema = schemaClone.definitions?.[schemaName];
              if (definitionSchema && typeof definitionSchema === "object") {
                definitionSchema.required = [];
              }
            }

            // Remove minItems on array for unrequired field.
            // Since sub-schema, passed string will need to be of format subschema_name.properties.array_name
            const possibleArrayToBeUnrequired: any = get(
              schemaClone,
              `definitions.${toRm}`
            );
            if (
              possibleArrayToBeUnrequired &&
              possibleArrayToBeUnrequired.type === "array"
            ) {
              possibleArrayToBeUnrequired.minItems = undefined;
            }
          }
        }

        // And also enum them as true/false with a default of false
        for (let i = 0; i < unRequired.length; i++) {
          let current;
          // unRequired is in the definitions
          if (
            unRequired[i].charAt(0) === unRequired[i].charAt(0).toUpperCase()
          ) {
            const brokenPath = unRequired[i].split(".");
            const schemaName = brokenPath[0];
            const schemaPath = brokenPath.slice(1).join(".");

            current = get(
              schemaClone.definitions,
              `${schemaName}.properties.${schemaPath}`
            );
          } else {
            current = schemaClone.properties[unRequired[i]];
          }

          if (!isObject(current)) {
            continue;
          }

          // If they are checkboxes
          if (current && current.type === "boolean") {
            current.enum = [true, false];
            current.default = false;
          }

          // If they are arrays
          if (current.type === "array") {
            current.minItems = undefined;
          }
        }
      }

      return schemaClone;
    }
  }, [schema, removableFrameworkFields]);

  return (
    <Form
      className="rjsf_form"
      uiSchema={{
        ...uiSchema,
        "ui:submitButtonOptions": {
          norender: true,
        },
      }}
      formContext={{
        formData: componentFormData,
      }}
      formData={componentFormData}
      onChange={(changeData) => {
        setComponentFormData(changeData.formData);

        if (onChange) {
          onChange(changeData);
        }
      }}
      fields={{
        fxtMultiSelectField: FXTMultiSelectField,
        fxtPatternPropField: FXTPatternPropField,
      }}
      schema={getProcessedSchema ?? {}}
      widgets={{
        SelectWidget: FXTSelectWidget,
        fxtSelectWidget: FXTSelectWidget,
      }}
      validator={validator}
      {...everythingElse}
    >
      <></>
    </Form>
  );
};

export default RjsfForm;
