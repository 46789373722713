export const API_ROUTE_CONFIGURATION = {
  // Fee Component
  feeComponent: "/componentFee",
  feeDetails: "/feeCalcDtl",

  // Interest Component
  interestComponent: "/componentInt",

  //Installment Loan - Collateral Components
  componentCollateral: "/componentCollateral",

  //Repayment
  repaymentComponent: "/componentRepay",

  // Limit Component
  limitComponent: "/componentLimit",

  // NSF
  nsfComponent: "/componentNsf",

  //Transaction Deposit
  componentTrnOpt: "/componentTrnOpt",

  // Rewards Component
  rewardComponent: "/componentReward",
  productProgram: "/prodProgram",
  productProgramGroup: "/prodProgramGroup",

  // Charge-off Component
  chargeOffComponent: "/componentDepChrgOff",

  // Term Component
  termComponent: "/componentTd",

  //rules component
  componentRules: "/componentRules",

  // Misc
  matrixType: "/matrixType",
  transactionStatGroup: "/trnStatGroup",
  transactionCodes: "/trnCode",
  promotionalCode: "/promoCodeDtl",

  // Account Types
  accountType: "/acctType",
  accumTrnLimit: "/accumTrnLimit",
  trnLimit: "/trnLimit",

  // Product Launchpad
  // My Products
  product: "/prod_bk",
  currencyCodes: "/ccyCode",
  loanDelinquency: "/prod_bkLnDelq",
  productType: "/prodType",
  productTdFilter: `/prod_bk?filter.q=ifxAcctType="CDA"`,
  productTdFilterCD: `/prod_bk?filter.q=ifxAcctType IN ("SDA", "DDA", "MMA")`,
  productSubType: "/prodSubType",
  prodGroup: "/prodGroup",

  //IC - Asset Catalog
  assetCatalogue: "/assetCatalogue",
  naicsCodes: "/naicsCode",

  //IC
  accountGroup: "/acctGroup",

  //IC - Financial Organization - General Organization Party Parameters
  partyOrgFininst: "/party_org_fininst",
  regionCode: "/cntrySubdivision",
  partyUSBankInfo: "/partyUSBankInfo",
  financialOrgAddress: "/addr",
  partyOrgFedwire: "/party_org_fininst_fedwire",

  //Roles
  role: "/role",

  //Bank Parameter
  financialOrg: "/party_org_fininst",
  timeZone: "/tmZone",
  FICalendar: "/calendar",
  defaultNetwork: "/network",
  bankParams: "/bankparam",
  bankParamsUSBankInfo: "/bankparamUSBankInfo",
  exceptRule: "/exceptRule",
  addrIdList: "/addr",

  //General Party Parameters
  country: "/cntry",

  //Financial Org
  countrySubDivision: "/cntrySubdivision",

  //Dashboard
  envStatus: "/environmentStatus",
  consolePermission: "/console_permissions",
  announcements: "/console/confluence/news/",
  environment: "/console/my/environment/",
  project: "/console/project/",
  requests: "/environment/requests",
  permissions: "/permissions",
  systemVersion: "/system/version",

  // Workflow
  institutionalConfig: "/ic_config",
  dashboard: "/dashboard",

  //General Ledger
  generalLedgerConfig: "/gl_config",
  accountingSegments: "/acctgSeg",
  accounts: "/acct_gl",
  systemAccounts: "/configSystemAcct",
  sets: "/glSet",
  positions: "/posn_gl",

  //Transaction codes
  tcConfig: "/tc_config",
  transCode: "/trnCode",
  tags: "/tags",
  language: "/language",
  templates: "/templates",

  //CRT
  crtConfig: "/crt_config",
  custRelType: "/custRelType",

  myOrgs: "/organizations",
  favOrgs: "/favOrgs",
  environments: "/environments",
  projects: "/projects",
  platform: "/platform",

  //access-management
  profileTemplate: "/profileTemplate",
  account: "/account",
  amAccounts: "/accounts",
  user: "/user",
  new: "/new",
  customRole: "/console_role",
  organization: "/organization",
  admin: "/admin",
  amEnvironment: "/environment",
  coreRoles: "/coreRoles",
  resetPassword: "/resetPassword",
  consoleRoles: "/console_role/admin",
  glSubAccounts: "/glSubBalType",
  consoleRole: "/console_role",
  consoleRolePermissions: "/my/console_permissions/admin",
  myAcc: "/my/account",
  altAccount: "/altAccount",

  envRequest: "/my/environment/requests",
  enrollment: "/enrollment",
  enrollmentHelp: "/enrollment/help",
  fastRequest: "/fast/ticketRequest",

  matrix: "/matrix",
};

const consoleBase = "/console";
const myConsoleBase = `${consoleBase}/my`;

export const CONSOLE_ENDPOINTS = {
  base: consoleBase,
  accounts: `${consoleBase}/accounts`,
  adminAccount: `${consoleBase}/account/admin`,
  envStatus: `${consoleBase}/organization/environmentStatus`,
  myOrganizations: `${myConsoleBase}/organizations`,
  myEnvironment: `${myConsoleBase}/environment`,
  allMyEnvironments: `${myConsoleBase}/environments`,
  ticketRequests: `${myConsoleBase}/environment/requests`,
  myCnslPermissions: `${myConsoleBase}/console_permissions`,
  corePermissions: `${consoleBase}/core/permissions`,
  projects: `${myConsoleBase}/projects`,
};
