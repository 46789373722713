import { mediaImg } from "public/assets";
import { DocumentSliderData } from "@/interfaces/document-center-documents";
import { AppRadioProps } from "../common/form-fields/radio-button-group/RadioButtonGroup";

const DOCUMENT_SLIDER: DocumentSliderData[] = [
  {
    title: "Finxact Academy",
    description:
      "Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla ornare faucibus ex, non facilisis nisl. Maecenas aliquet mauris ut tempus Proin ut dui sed metus pharetra hend rerit vel non mi. ",
    tagList: ["React", "Node Js"],
    editorInfo: {
      name: "John Doe",
      date: "10/25/2022",
    },
    arrowLinkPath: "javascript:void(0)",
    mediaInfo: mediaImg,
  },
  {
    title: "Finxact Academy",
    description:
      "Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla ornare faucibus ex, non facilisis nisl. Maecenas aliquet mauris ut tempus Proin ut dui sed metus pharetra hend rerit vel non mi. ",
    tagList: ["React", "Node Js"],
    editorInfo: {
      name: "John Doe",
      date: "10/25/2022",
    },
    arrowLinkPath: "javascript:void(0)",
    mediaInfo: mediaImg,
  },
  {
    title: "Finxact Academy",
    description:
      "Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla ornare faucibus ex, non facilisis nisl. Maecenas aliquet mauris ut tempus Proin ut dui sed metus pharetra hend rerit vel non mi. ",
    tagList: ["React", "Node Js"],
    editorInfo: {
      name: "John Doe",
      date: "10/25/2022",
    },
    arrowLinkPath: "javascript:void(0)",
    mediaInfo: mediaImg,
  },
  {
    title: "Finxact Academy",
    description:
      "Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla ornare faucibus ex, non facilisis nisl. Maecenas aliquet mauris ut tempus Proin ut dui sed metus pharetra hend rerit vel non mi. ",
    tagList: ["React", "Node Js"],
    editorInfo: {
      name: "John Doe",
      date: "10/25/2022",
    },
    arrowLinkPath: "javascript:void(0)",
    mediaInfo: mediaImg,
  },
  {
    title: "Finxact Academy",
    description:
      "Proin ut dui sed metus pharetra hend rerit vel non mi. Nulla ornare faucibus ex, non facilisis nisl. Maecenas aliquet mauris ut tempus Proin ut dui sed metus pharetra hend rerit vel non mi. ",
    tagList: ["React", "Node Js"],
    editorInfo: {
      name: "John Doe",
      date: "10/25/2022",
    },
    arrowLinkPath: "javascript:void(0)",
    mediaInfo: mediaImg,
  },
];

const REMOVE_USER_RADIO_LIST: AppRadioProps<any>[] = [
  {
    label: "Remove from this console instance",
    value: "1",
  },
  {
    label: "Remove from all console instances",
    value: "2",
  },
];
const NAME_OF_CYCLE: string[] = ["Business day", "Day", "Month", "Year"];
const NUMBER_OF_DAYS: string[] = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "E",
];
const NON_BUSINESS_DAY: string[] = [
  "Actual date",
  "Next business date",
  "Previous business date",
];

const CHARGE_OFF_TRANSACTION_CODE: AppRadioProps<any>[] = [
  {
    label: "Select charge off transaction code field",
    value: "1",
  },
  {
    label: "Select charge off transaction code matrix",
    value: "2",
  },
];
export {
  DOCUMENT_SLIDER,
  NAME_OF_CYCLE,
  NUMBER_OF_DAYS,
  NON_BUSINESS_DAY,
  REMOVE_USER_RADIO_LIST,
  CHARGE_OFF_TRANSACTION_CODE,
};
