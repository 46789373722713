"use client";

import { WorkflowResponseUnion } from "@/api-config/api-service";
import {
  ProductGroupInfo,
  ProductType,
  ProductWorkflowModelType,
  defaultGroupInfo,
} from "@/components/product-management";
import { CreateMatrixMatrixesPayload } from "@/components/product-management/model/types";
import {
  SideBarMenuStatusType,
  StatusSideBarMainMenu,
  StatusSideBarMenuDefaultProps,
  FlyoutAndTooltipType,
  DocLinksType,
} from "@/models/global-models";
import {
  ConfigFlowBtnType,
  IFX_ACCT_TYPE,
  IfxAcctProductSubType,
  IfxAcctProductType,
} from "@/utils";
// Importing from common to avoid circular dependency issue
import { deepCopy } from "@/utils/common";
import { createContext } from "react";

type ProductConfigurationContextType = {
  showSidebar?: boolean;
  saveAndExit?: { status: boolean; url: string };
  upcomingRoutePath?: string;
  currentFeatureDetails?: StatusSideBarMenuDefaultProps;
  formStatus?: SideBarMenuStatusType;
  sideBarMainMenuIndex: number;
  sideBarSubMenuIndex: number | null;
  canCheckFormStatus: boolean;
  canNavigate: boolean;
  isGoingBack: boolean;
  sideBarMenuList?: StatusSideBarMainMenu[];
  currProductId: string;
  currProductName?: string;
  productDetails: ProductType | null;
  prodMatrix: CreateMatrixMatrixesPayload | null;
  productGroupInfo?: ProductGroupInfo | null;
  isSummaryQucikStartFlow: boolean;
  isLoading: boolean;
  loadingCounter: number;
  entityWorkflowData: WorkflowResponseUnion[] | null;
  entityId: string;
  tooltipFlyoutDetails?: FlyoutAndTooltipType | null;
  currDocsFlyoutData?: DocLinksType[] | null;
  currRouteUid: string;
  showHideSideBar?: (value: boolean) => void;
  productWorkflowData?: ProductWorkflowModelType[] | null;
  ifxAcctType: IFX_ACCT_TYPE;
  ifxAcctProductType?: IfxAcctProductType;
  ifxAcctProductSubType?: IfxAcctProductSubType;
  isSaveDisabled?: boolean;
  selectedBtnType?: ConfigFlowBtnType;
  configPageTitle: string;
  setSideBarCurrentIndex?: (menu: number, subMenu: number | null) => void;
  setUpcomingRoutePath?: (path: string) => void;
  updateFormStatus?: (status: SideBarMenuStatusType) => void;
  updateSidebarMenu?: (menu: StatusSideBarMainMenu[]) => void;
  checkFormStatus?: (value: boolean) => void;
  onSaveDisabled?: (value: boolean) => void;
  updateGoingBackState?: (val: boolean) => void;
  navigateToNextPage?: (type?: ConfigFlowBtnType) => void;
  updateStatusInSideBarMenuList?: (status: SideBarMenuStatusType) => void;
  setConfigPageTitle: (title: string) => void;
  setProductId?: (param: string) => void;
  setProductDetails?: (details: ProductType | null) => void;
  setProdMatrix?: (prodMatrix: CreateMatrixMatrixesPayload | null) => void;
  setProductGroupInfo?: (groupInfo: ProductGroupInfo | null) => void;
  resetProductInfo?: () => void;
  setSummaryQuickStartFlag?: (flag: boolean) => void;
  navigateWithProductNameParam: (route: string, param?: string) => void;
  navigateTo: (route: string) => void;
  getQueryParam: (key: string) => string;
  getExistingProduct?: (name: string, enableNav: boolean) => void;
  updateProductDetailsInContext?: (prodDetails: ProductType) => void;
  showLoader?: (loading: boolean) => void;
  getCurrentStageStatusName: () => string;
  getCurrentComponentClass?: () => string;
  setEntityWorkflowData: (val: WorkflowResponseUnion[]) => void;
  setEntityId?: (id: string) => void;
  [key: string]: any;
  setCurrFlyoutsAndTooltipData?: (data: FlyoutAndTooltipType[] | null) => void;
  setCurrDocsFlyoutData?: (data: DocLinksType[] | null) => void;
  setCurrRouteUid?: (uid: string) => void;
  setProductWorkflowData?: (details: ProductWorkflowModelType[] | null) => void;
  getWorflowStatusToBeUpdated?: () => string | null;
  getCurrentWorkflowIndex?: () => number | null;
  navigateWithEntityParam?: (route: string) => void;
  setIfxAcctProdType?: (
    ifxAcctType: string,
    prodType?: IfxAcctProductType,
    prodSubType?: IfxAcctProductSubType
  ) => void;
  setIfxAcctType?: (ifxAcctType: string) => void;
  setSaveAndExit: (svextStatus: boolean, url: string) => void;
  updateCanNavigateState?: () => void;
  getCurrSideBarMenu?: () => void;
  setSelectedBtnType?: (btnType: ConfigFlowBtnType) => void;
  continueToConfigFlowFromTc?: (tcId?: string) => void;
  getCurrentProductAttributeWorkflowStatus?: () => string;
};

const defautlComponentConfigContextVal: ProductConfigurationContextType = {
  showSidebar: true,
  saveAndExit: { status: false, url: "" },
  upcomingRoutePath: "",
  sideBarMainMenuIndex: 0,
  sideBarSubMenuIndex: 0,
  formStatus: "NOT_STARTED",
  canCheckFormStatus: false,
  canNavigate: false,
  isGoingBack: false,
  sideBarMenuList: [],
  currProductId: "",
  currProductName: "",
  productDetails: null,
  prodMatrix: null,
  productGroupInfo: deepCopy(defaultGroupInfo),
  isSummaryQucikStartFlow: false,
  isLoading: false,
  loadingCounter: 0,
  entityWorkflowData: null,
  title: "",
  entityId: "",
  configPageTitle: "",
  setEntityWorkflowData: () => {},
  setConfigPageTitle: () => {},
  tooltipFlyoutDetails: null,
  currDocsFlyoutData: null,
  currRouteUid: "",
  ifxAcctType: "" as IFX_ACCT_TYPE,
  isSaveDisabled: false,
  showLoader: function (loading: boolean): void {},
  navigateWithProductNameParam: function (route: string): void {
    throw new Error("Function not implemented.");
  },
  navigateTo: function (route: string): void {
    throw new Error("Function not implemented.");
  },
  getQueryParam: function (key: string): string {
    throw new Error("Function not implemented.");
  },
  getCurrentStageStatusName: () => "",
  setSaveAndExit: function (svextStatus: boolean, url: string): void {},
};

type ProductConfigurationReducerTypes =
  | "EnableSideBar"
  | "SetSideBarCurrentIndex"
  | "SetRoutePath"
  | "canCheckFormStatus"
  | "updateGoingBackState"
  | "UpdateSideBarMenuList"
  | "CanNavigateToNextPage"
  | "UpdateFormStatus"
  | "UpdateCurrentFeatureDetails"
  | "SetProductId"
  | "SetProductName"
  | "SetProductDetails"
  | "SetProdMatrix"
  | "SetProductGroupInfo"
  | "SetSummaryQuickStartFlag"
  | "ShowLoader"
  | "SetEntityWorkflowData"
  | "SetEntityId"
  | "SetCurrFlyoutsAndTooltipData"
  | "SetCurrDocsFlyoutData"
  | "SetCurrRouteUid"
  | "SetProductWorkflowData"
  | "SetIfxAcctProdType"
  | "SaveAndExit"
  | "IsSaveDisabled"
  | "setSelectedBtnType"
  | "SetConfigPageTitle";

type ProductConfigurationContextAction = {
  type: ProductConfigurationReducerTypes;
  payload: any;
};

function productConfigurationReducer(
  state: ProductConfigurationContextType,
  action: ProductConfigurationContextAction
) {
  const { type, payload } = action;

  switch (type) {
    case "EnableSideBar":
      return {
        ...state,
        showSidebar: payload,
      };
    case "ShowLoader":
      return {
        ...state,
        isLoading: payload,
        loadingCounter: payload
          ? state.loadingCounter + 1
          : state.loadingCounter > 0
            ? state.loadingCounter - 1
            : 0,
      };
    case "SetRoutePath":
      return {
        ...state,
        upcomingRoutePath: payload,
      };
    case "canCheckFormStatus":
      return {
        ...state,
        canCheckFormStatus: payload,
      };
    case "updateGoingBackState":
      return {
        ...state,
        isGoingBack: payload,
      };
    case "IsSaveDisabled":
      return {
        ...state,
        isSaveDisabled: payload,
      };
    case "UpdateFormStatus":
      return {
        ...state,
        formStatus: payload,
      };
    case "UpdateSideBarMenuList":
      return {
        ...state,
        sideBarMenuList: payload,
      };
    case "CanNavigateToNextPage":
      return {
        ...state,
        canNavigate: !state.canNavigate,
      };
    case "SetProductId":
      return {
        ...state,
        currProductId: payload,
      };
    case "SetProductName":
      return {
        ...state,
        currProductName: payload,
      };
    case "SetProductDetails":
      return {
        ...state,
        productDetails: payload,
      };
    case "SetProdMatrix":
      return {
        ...state,
        prodMatrix: payload,
      };
    case "SetSummaryQuickStartFlag":
    case "SetProductGroupInfo":
      return {
        ...state,
        productGroupInfo: payload,
      };
    case "UpdateCurrentFeatureDetails":
      return {
        ...state,
        currentFeatureDetails: payload,
      };
    case "SetSummaryQuickStartFlag":
      return {
        ...state,
        isSummaryQucikStartFlow: payload,
      };
    case "SetSideBarCurrentIndex":
      return {
        ...state,
        sideBarMainMenuIndex: payload.sideBarMainMenuIndex,
        sideBarSubMenuIndex: payload.sideBarSubMenuIndex,
      };
    case "SetEntityWorkflowData":
      return {
        ...state,
        entityWorkflowData: payload,
      };
    case "SetEntityId":
      return {
        ...state,
        entityId: payload,
      };
    case "SetCurrFlyoutsAndTooltipData":
      return {
        ...state,
        tooltipFlyoutDetails: payload,
      };
    case "SetCurrDocsFlyoutData":
      return {
        ...state,
        currDocsFlyoutData: payload,
      };
    case "SetCurrRouteUid":
      return {
        ...state,
        currRouteUid: payload,
      };
    case "SetProductWorkflowData":
      return {
        ...state,
        productWorkflowData: payload,
      };
    case "SetIfxAcctProdType":
      return {
        ...state,
        ifxAcctType: payload.ifxAcctType || state.ifxAcctType,
        ifxAcctProductType: payload.prodType || state.prodType,
        ifxAcctProductSubType:
          payload.ifxAcctProductSubType || state.ifxAcctProductSubType,
      };
    case "SaveAndExit":
      return {
        ...state,
        saveAndExit: { ...payload },
      };
    case "setSelectedBtnType":
      return {
        ...state,
        selectedBtnType: payload,
      };
    case "SetConfigPageTitle":
      return {
        ...state,
        configPageTitle: payload,
      };
    default:
      return {
        ...state,
      };
  }
}

const productConfigurationContext =
  createContext<ProductConfigurationContextType>(
    defautlComponentConfigContextVal
  );

//type export
export type { ProductConfigurationContextType };

//conext exports
export {
  productConfigurationContext,
  defautlComponentConfigContextVal,
  productConfigurationReducer,
};
