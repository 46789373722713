"use client";

import React, { useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";
import Iframe from "react-iframe";
import { useUserInfo } from "@/app/providers";
import { QualtricsEnvId } from "@/utils/constants";

import "./footer-iframe.scss";
import { Box } from "@chakra-ui/react";

const FooterWidgetIframe = ({ className }: { className?: string }) => {
  const queryParams = useSearchParams();
  const [iframeKey, setIframeKey] = useState(0);
  useEffect(() => {
    setIframeKey((prevKey) => prevKey + 1);
  }, [queryParams]);

  const user = useUserInfo();
  const userIdState = user.user?.email || "Unknown Email";
  const userFirstName = user.user?.firstName || "Anonymous";
  const userLastName = user.user?.lastName || "Anonymous";
  const userOrg = user.user?.organization || "Unknown Org";
  const pathname = window.location.href;

  return (
    <Box className="qualtrics-container">
      <Iframe
        key={String(iframeKey)}
        url={`https://fiservcx.qualtrics.com/jfe/form/${QualtricsEnvId}?Source=${pathname}&UserId=${userIdState}&FirstName=${userFirstName}&LastName=${userLastName}&OrgName=${userOrg}`}
        id=""
        className={`qualtrics-widget-inline ${className}`}
        display="block"
        position="relative"
      />
    </Box>
  );
};
export default FooterWidgetIframe;
