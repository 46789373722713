import { StyleFunctionProps, extendTheme, theme } from "@chakra-ui/react";
import styleVars from "../styles/_export.module.scss";

const {
  appBgColor,
  // Font
  appFontColor,
  appFontFamilyRegular,
  appFontFamilySemiBold,
  appDefaultFontBaseSize,
  appDefaultFontSize,
  fontSizeXXL,
  fontSizeXL,
  fontSizeL,
  fontSize2rem,
  fontSizeMed,
  fontSizeReg,
} = styleVars;

const themes = {
  styles: {
    global: () => ({
      body: {
        fontFamily: `${appFontFamilyRegular}`,
        bg: `${appBgColor}`,
        color: `${appFontColor}`,
        fontSize: `${appDefaultFontSize}`,
      },
      html: {
        fontSize: `${appDefaultFontBaseSize}`,
      },
      "h1, h2, h3, h4, h5, h6": {
        fontFamily: `${appFontFamilySemiBold} !important`,
        fontWeight: 600,
      },
      h1: {
        fontSize: fontSizeXXL,
        lineHeight: "6rem",
      },
      h2: {
        fontSize: fontSizeXL,
        lineHeight: "4rem",
      },
      h3: {
        fontSize: fontSizeL,
        lineHeight: "3.2rem",
      },
      h4: {
        fontSize: fontSize2rem,
        lineHeight: "2.8rem",
      },
      h5: {
        fontSize: fontSizeMed,
        lineHeight: "2.4rem",
      },
      h6: {
        fontSize: fontSizeReg,
        lineHeight: "2.4rem",
        fontWeight: 500,
      },
    }),
  },
  space: {
    px: "1px",
    0.5: "0.125rem",
    0.8: "0.2rem",
    1: "0.25rem",
    1.5: "0.375rem",
    1.6: "0.4rem",
    2: "0.5rem",
    2.4: "0.6rem",
    2.5: "0.625rem",
    3: "0.75rem",
    3.2: "0.8rem",
    3.5: "0.875rem",
    4: "1rem",
    4.8: "1.2rem",
    5: "1.25rem",
    5.6: "1.4rem",
    6: "1.5rem",
    6.4: "1.6rem",
    7: "1.75rem",
    7.2: "1.8rem",
    8: "2rem",
    8.8: "2.2rem",
    9: "2.25rem",
    9.6: "2.4rem",
    10: "2.5rem",
    11: "2.8rem",
    12: "3rem",
    12.8: "3.2rem",
    13: "3.25rem",
    14: "3.5rem",
    16: "4rem",
    17: "4.5rem",
    18.4: "4.6rem",
    20: "5rem",
    21.6: "5.4rem",
    24: "6rem",
    25: "6.3rem",
    25.6: "6.4rem",
    26: "6.5rem",
    28: "7rem",
    30: "7.5rem",
    32: "8rem",
    34: "8.5rem",
    36: "9rem",
    39.6: "9.7rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    81: "21rem",
    96: "24rem",
    100: "25rem",
    120: "70rem",
    130: "30rem",
    140: "40rem",
    168: "42rem",
    200: "90rem",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
};
const breakpoints = {
  base: "0px",
  sm: "360px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};
const AppTheme = extendTheme({ ...themes, ...breakpoints });

export default AppTheme;
