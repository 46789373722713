"use client";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  _throwError,
  createComponent,
  getCoreEndpoint,
  getCoreModelEndpoint,
  updateComponent,
  updateComponentWithPut,
  updateProduct,
  updateWorkflowGeneric,
} from "@/api-config/api-service";
import {
  ComponentCreatePayload,
  FeeComponentUpdatePayload,
  RewardComponentCreatePayload,
  RewardComponentUpdatePayload,
  LimitComponentUpdatePayload,
  TermComponentUpdatePayload,
  CollateralComponentUpdatePayload,
  NsfComponentUpdatePayload,
  InterestComponentUpdatePayload,
  ChargeOffComponentUpdatePayload,
  CollateralComponentData,
  FeeComponentResponse,
  InterestComponentResponse,
  TransactionComponentUpdatePayload,
  ProductResponse,
  RepaymentComponentUpdatePayload,
  AssetCatalogue,
  LoanDelinquencyType,
  MatrixTypePayload,
  CreateMatrixPayload,
  MatrixResponse,
  ToastConfig,
  RulesCompnentCreatePayload,
} from "../model/types";
import { ProductType, createProduct } from "..";
import {
  AlertStatus,
  CreateToastFnReturn,
  useToast,
} from "../../ChakraUiManager";
import {
  COMPONENT_CLASS,
  DASHBOARD_ACCORDION_STATUS,
  MATRIX_NAME,
  VALID_FROM_DTM,
} from "@/utils/constants";
import { ApiClient } from "@/api-config";
import {
  MATRIX_TYPE_DESCRIPTION,
  MATRIX_TYPE_PROP_FMT,
  MATRIX_TYPE_PROP_LABEL,
  MATRIX_TYPE_PROP_NAME,
  MATRIX_TYPE_PROP_TYPE,
  MATRIX_TYPE_PROP_WHEN_BTWN,
  MATRIX_VAL_TYPE,
  convertArrToObj,
} from "@/utils";
import {
  FinOrgRuleGroups,
  FinOrgRulesProps,
} from "@/components/institutional-configuration/model";
import { consoleDark } from "@uiw/codemirror-theme-console";

const WORKFLOW_STATUS = {
  inProgress: "in_progress",
  completed: "completed",
};

export interface ToastConfigTypes {
  toastId: string;
  msg: string;
  errMsg: string;
  status: AlertStatus;
  toastRef: CreateToastFnReturn;
}

/** Create APIs **/

// Create Fee Component
export const createFeeComponent = async (config: FeeComponentUpdatePayload) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    productDetails,
  } = config;

  const latestCompVer = await getCompLatestVersion(
    COMPONENT_CLASS["fee"],
    formData.componentName!,
  );
  if (latestCompVer && latestCompVer.data.length) {
    formData.version = latestCompVer.data?.[0]?.version + 1;
  }

  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.feeComponent}`,
    body: JSON.stringify(formData),
  })
    .then(async (res: ComponentCreatePayload["formData"]) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await Promise.all([
        _updateProduct(
          productDetails,
          res.componentName,
          COMPONENT_CLASS["fee"],
          formData?.version!,
        ),
        productDetails?.status !== DASHBOARD_ACCORDION_STATUS.completed &&
        stageName
          ? _updateWorkflow(
              productDetails.name,
              stageName,
              COMPONENT_CLASS["fee"],
            )
          : "",
      ]);
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e?.errors?.[0]?.errorDesc || "Unable to create component";
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create Interest Component
export const createInterestComponent = async (
  config: InterestComponentUpdatePayload,
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    productDetails,
  } = config;

  const latestCompVer = await getCompLatestVersion(
    COMPONENT_CLASS["interest"],
    formData.componentName!,
  );
  if (latestCompVer && latestCompVer.data.length) {
    formData.version = latestCompVer.data?.[0]?.version + 1;
  }

  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.interestComponent}`,
    body: JSON.stringify(formData),
  })
    .then(async (res: ComponentCreatePayload["formData"]) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await Promise.all([
        _updateProduct(
          productDetails,
          res.componentName,
          COMPONENT_CLASS["interest"],
          formData?.version!,
        ),
        productDetails?.status !== DASHBOARD_ACCORDION_STATUS.completed &&
        stageName
          ? _updateWorkflow(
              productDetails.name,
              stageName,
              COMPONENT_CLASS["interest"],
            )
          : "",
      ]);
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create Limit Component
export const createLimitComponent = async (
  config: LimitComponentUpdatePayload,
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    productDetails,
  } = config;

  const latestCompVer = await getCompLatestVersion(
    COMPONENT_CLASS["limit"],
    formData.componentName!,
  );
  if (latestCompVer && latestCompVer.data.length) {
    formData.version = latestCompVer.data?.[0]?.version + 1;
  }

  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.limitComponent}`,
    body: JSON.stringify(formData),
  })
    .then(async (res: ComponentCreatePayload["formData"]) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await Promise.all([
        _updateProduct(
          productDetails,
          res.componentName,
          COMPONENT_CLASS["limit"],
          formData?.version!,
        ),
        productDetails?.status !== DASHBOARD_ACCORDION_STATUS.completed &&
        stageName
          ? _updateWorkflow(
              productDetails.name,
              stageName,
              COMPONENT_CLASS["limit"],
            )
          : "",
      ]);
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create Term Component
export const createTermComponent = async (
  config: TermComponentUpdatePayload,
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    productDetails,
  } = config;

  const latestCompVer = await getCompLatestVersion(
    COMPONENT_CLASS["term"],
    formData.componentName!,
  );
  if (latestCompVer && latestCompVer.data.length) {
    formData.version = latestCompVer.data?.[0]?.version + 1;
  }

  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.termComponent}`,
    body: JSON.stringify(formData),
  })
    .then(async (res: ComponentCreatePayload["formData"]) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await Promise.all([
        _updateProduct(
          productDetails,
          res.componentName,
          COMPONENT_CLASS["term"],
          formData?.version!,
        ),
        productDetails?.status !== DASHBOARD_ACCORDION_STATUS.completed &&
        stageName
          ? _updateWorkflow(
              productDetails.name,
              stageName,
              COMPONENT_CLASS["term"],
            )
          : "",
      ]);
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create NSF Component
export const createNSFComponent = async (config: NsfComponentUpdatePayload) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    productDetails,
  } = config;

  const latestCompVer = await getCompLatestVersion(
    COMPONENT_CLASS["nsf"],
    formData.componentName!,
  );
  if (latestCompVer && latestCompVer.data.length) {
    formData.version = latestCompVer.data?.[0]?.version + 1;
  }

  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.nsfComponent}`,
    body: JSON.stringify(formData),
  })
    .then(async (res: ComponentCreatePayload["formData"]) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await Promise.all([
        _updateProduct(
          productDetails,
          res.componentName,
          COMPONENT_CLASS["nsf"],
          formData?.version!,
        ),
        productDetails?.status !== DASHBOARD_ACCORDION_STATUS.completed &&
        stageName
          ? _updateWorkflow(
              productDetails.name,
              stageName,
              COMPONENT_CLASS["nsf"],
            )
          : "",
      ]);
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create ChargeOff Component
export const createChargeOffComponent = async (
  config: ChargeOffComponentUpdatePayload,
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    productDetails,
  } = config;

  const latestCompVer = await getCompLatestVersion(
    COMPONENT_CLASS["chargeOff"],
    formData.componentName!,
  );
  if (latestCompVer && latestCompVer.data.length) {
    formData.version = latestCompVer.data?.[0]?.version + 1;
  }

  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.chargeOffComponent}`,
    body: JSON.stringify(formData),
  })
    .then(async (res: ComponentCreatePayload["formData"]) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await Promise.all([
        _updateProduct(
          productDetails,
          res.componentName,
          COMPONENT_CLASS["chargeOff"],
          formData?.version!,
        ),
        productDetails?.status !== DASHBOARD_ACCORDION_STATUS.completed &&
        stageName
          ? _updateWorkflow(
              productDetails.name,
              stageName,
              COMPONENT_CLASS["chargeOff"],
            )
          : "",
      ]);
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};
// Create Transaction Deposit Component
export const createTransactionDepositComponent = async (
  config: TransactionComponentUpdatePayload,
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    productDetails,
  } = config;

  const latestCompVer = await getCompLatestVersion(
    COMPONENT_CLASS["transactionDeposit"],
    formData.componentName!,
  );
  if (latestCompVer && latestCompVer.data.length) {
    formData.version = latestCompVer.data?.[0]?.version + 1;
  }

  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.componentTrnOpt}`,
    body: JSON.stringify(formData),
  })
    .then(async (res: ComponentCreatePayload["formData"]) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await Promise.all([
        _updateProduct(
          productDetails,
          res.componentName,
          COMPONENT_CLASS["transactionDeposit"],
          formData?.version!,
        ),
        productDetails?.status !== DASHBOARD_ACCORDION_STATUS.completed &&
        stageName
          ? _updateWorkflow(
              productDetails.name,
              stageName,
              COMPONENT_CLASS["transactionDeposit"],
            )
          : "",
      ]);
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create Reward Component
export const createRewardComponent = async (
  config: RewardComponentUpdatePayload,
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    productDetails,
  } = config;

  const latestCompVer = await getCompLatestVersion(
    COMPONENT_CLASS.reward,
    formData.componentName!,
  );
  if (latestCompVer && latestCompVer.data.length) {
    formData.version = latestCompVer.data?.[0]?.version + 1;
  }

  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.rewardComponent}`,
    body: JSON.stringify(formData),
  })
    .then(async (res: RewardComponentCreatePayload["formData"]) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await Promise.all([
        _updateProduct(
          productDetails,
          res.componentName,
          COMPONENT_CLASS.reward,
          formData?.version!,
        ),
        productDetails?.status !== DASHBOARD_ACCORDION_STATUS.completed &&
        stageName
          ? _updateWorkflow(
              productDetails.name,
              stageName,
              COMPONENT_CLASS.reward,
            )
          : "",
      ]);
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create Repayment Component
export const createRepaymentComponent = async (
  config: RepaymentComponentUpdatePayload,
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    productDetails,
  } = config;

  const latestCompVer = await getCompLatestVersion(
    COMPONENT_CLASS["repay"],
    formData.componentName!,
  );
  if (latestCompVer && latestCompVer.data.length) {
    formData.version = latestCompVer.data?.[0]?.version + 1;
  }

  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.repaymentComponent}`,
    body: JSON.stringify(formData),
  })
    .then(async (res: ComponentCreatePayload["formData"]) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await Promise.all([
        _updateProduct(
          productDetails,
          res.componentName,
          COMPONENT_CLASS["repay"],
          formData?.version!,
        ),
        productDetails?.status !== DASHBOARD_ACCORDION_STATUS.completed &&
        stageName
          ? _updateWorkflow(
              productDetails.name,
              stageName,
              COMPONENT_CLASS["repay"],
            )
          : "",
      ]);
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};
/** Update APIs **/

// Create Collateral Component
export const createCollateralComponent = async (
  config: CollateralComponentUpdatePayload,
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    productDetails,
  } = config;

  const latestCompVer = await getCompLatestVersion(
    COMPONENT_CLASS["collateral"],
    formData.componentName!,
  );
  if (latestCompVer && latestCompVer.data.length) {
    formData.version = latestCompVer.data?.[0]?.version + 1;
  }

  return createComponent({
    url: `${API_ROUTE_CONFIGURATION.componentCollateral}`,
    body: JSON.stringify(formData),
  })
    .then(async (res: ComponentCreatePayload["formData"]) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      await Promise.all([
        _updateProduct(
          productDetails,
          res.componentName,
          COMPONENT_CLASS["collateral"],
          formData?.version!,
        ),
        productDetails?.status !== DASHBOARD_ACCORDION_STATUS.completed &&
        stageName
          ? _updateWorkflow(
              productDetails.name,
              stageName,
              COMPONENT_CLASS["collateral"],
            )
          : "",
      ]);
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

export const createRulesComponent = async (
  config: RulesCompnentCreatePayload,
) => {
  const {
    formData,
    toastOptions: { toast, toastId, successMessage },
    stageName,
    productDetails,
  } = config;
  return await Promise.all([
    _updateProduct(
      productDetails,
      formData.componentName,
      COMPONENT_CLASS.rules,
      formData.version,
    ),
    productDetails?.status !== DASHBOARD_ACCORDION_STATUS.completed && stageName
      ? _updateWorkflow(
          productDetails.name,
          DASHBOARD_ACCORDION_STATUS.completed,
          COMPONENT_CLASS.rules,
        )
      : "",
  ])
    .then((res) => {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

const _updateProduct = async (
  productDetails: ProductType,
  componentName: string,
  componentClass: string,
  version?: number,
) => {
  // Get the product
  const { components, name, _vn } = productDetails!;
  let updatedComponentList: ProductType["components"] = [];

  if (components?.length) {
    let componentIndex = components?.findIndex(
      (comp) => comp.componentClass === componentClass,
    );

    //product with matrix integration new component won't there in product component list so setting last index here
    if (componentIndex < 0) {
      componentIndex = components.length;
    }

    if (componentIndex !== -1) {
      updatedComponentList = [...components];
      updatedComponentList.splice(componentIndex, 1, {
        componentClass,
        componentName,
        version: version ?? 1,
      });
    }
  } else {
    updatedComponentList = [
      {
        componentClass,
        componentName,
        version: 1,
      },
    ];
  }
  // Add the newly created component in the product
  if (updatedComponentList?.length) {
    return updateProduct({
      productName: name,
      body: JSON.stringify({
        _vn: _vn,
        components: [...updatedComponentList],
      }),
    });
  }
};

const _updateWorkflow = async (
  productName: string,
  stageName: string,
  componentClass: string,
) => {
  return updateWorkflowGeneric({
    model: API_ROUTE_CONFIGURATION.product,
    key: productName,
    stage: componentClass,
    status: stageName,
  });
};

/** Get APIs **/
// Fee - General Feature Setup
export const getFeeComponent = async (
  productName: string,
  version: number,
): Promise<FeeComponentResponse> => {
  // Hardcoding the version as it will never change
  return getComponent(
    `${API_ROUTE_CONFIGURATION.feeComponent}/${productName}/${version}`,
  );
};

// Collateral General Feature Setup
export const getCollateralComponent = async (
  productName: string,
  version: number,
): Promise<CollateralComponentData> => {
  // Hardcoding the version as it will never change
  return getComponent(
    `${API_ROUTE_CONFIGURATION.componentCollateral}/${productName}/${version}`,
  );
};

export const getInterestComponent = async (
  productName: string,
  version: number,
): Promise<InterestComponentResponse> => {
  // Hardcoding the version as it will never change
  return getComponent(
    `${API_ROUTE_CONFIGURATION.interestComponent}/${productName}/${version}`,
  );
};

export const getLimitComponent = async (
  productName: string,
  version: number,
) => {
  // Hardcoding the version as it will never change
  return getComponent(
    `${API_ROUTE_CONFIGURATION.limitComponent}/${productName}/${version}`,
  );
};

export const getNsfComponent = async (productName: string, version: number) => {
  // Hardcoding the version as it will never change
  return getComponent(
    `${API_ROUTE_CONFIGURATION.nsfComponent}/${productName}/${version}`,
  );
};

export const getTermComponent = async (
  productName: string,
  version: number,
) => {
  // Hardcoding the version as it will never change
  return getComponent(
    `${API_ROUTE_CONFIGURATION.termComponent}/${productName}/${version}`,
  );
};

export const getRewardComponent = async (
  productName: string,
  version: number,
) => {
  return getComponent(
    `${API_ROUTE_CONFIGURATION.rewardComponent}/${productName}/${version}`,
  );
};

export const getRepaymentComponent = async (
  productName: string,
  version: number,
) => {
  return getComponent(
    `${API_ROUTE_CONFIGURATION.repaymentComponent}/${productName}/${version}`,
  );
};

export const getRulesComponent = async (
  productName: string,
  version: number,
) => {
  return getComponent(
    `${API_ROUTE_CONFIGURATION.componentRules}/${productName}/${version}`,
  );
};

export const getChargeOffComponent = async (
  productName: string,
  version: number,
) => {
  // Hardcoding the version as it will never change
  return getComponent(
    `${API_ROUTE_CONFIGURATION.chargeOffComponent}/${productName}/${version}`,
  );
};

export const getTransactionDepositComponent = async (
  productName: string,
  version: number,
) => {
  // Hardcoding the version as it will never change
  return getComponent(
    `${API_ROUTE_CONFIGURATION.componentTrnOpt}/${productName}/${version}`,
  );
};

export const getComponent = async (url: string) => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(url);
};

export const getProduct = async (
  productName: string,
): Promise<ProductResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(`/prod_bk/${productName}`);
};

export const getComponentFromProduct = (
  product: ProductType,
  componentClass: string,
) => {
  const { components } = product;

  return components?.find((comp) => comp.componentClass === componentClass);
};

export const getSelectedFeatureData = async (
  componentClass: string,
  productName: string,
  version: number,
) => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  //call all data apis required for components to map the options of that compoennt
  switch (componentClass) {
    case "componentFee": {
      const [feeData, feeTypeOptions] = await Promise.all([
        getComponent(
          `${API_ROUTE_CONFIGURATION.feeComponent}/${productName}/${version}`,
        ),
        api.options(API_ROUTE_CONFIGURATION.feeComponent),
      ]).then((data) => data);
      const postSchema = feeTypeOptions.actions.POST;
      const { feeTypeOpt } = postSchema.definitions.FeeItems.properties;
      const { earningsAnalysis } = postSchema.properties;
      const earningsAnalysisMap = earningsAnalysis.enum.reduce(
        (acc: any, opt: any, index: number) => {
          acc[opt] = earningsAnalysis.enumNames[index];
          return acc;
        },
        {},
      );
      return feeData.data[0]
        ? {
            ...feeData.data[0],
            feeItems: feeData.data[0].feeItems?.map((item: any) => ({
              ...item,
              feeTypeOpt: feeTypeOpt.enumNames[item.feeTypeOpt],
            })),
            earningsAnalysis:
              earningsAnalysisMap[feeData.data[0]?.earningsAnalysis] ?? "",
          }
        : {};
    }
    case "componentInt": {
      const [interestData, interestOptions] = await Promise.all([
        getComponent(
          `${API_ROUTE_CONFIGURATION.interestComponent}/${productName}/${version}`,
        ),
        api.options(API_ROUTE_CONFIGURATION.interestComponent),
      ]).then((data) => data);
      // condition for intitial for to be checked
      const {
        calcMthd,
        balOpt,
        disbmtOpt,
        postRoundOpt,
        negAccrOpt,
        accrCarryOverOpt,
      } = interestOptions.actions.POST.properties;

      const postRoundOptMap = postRoundOpt.enum.reduce(
        (acc: any, opt: any, index: number) => {
          acc[opt] = postRoundOpt.enumNames[index];
          return acc;
        },
        {},
      );

      return interestData.data[0]
        ? {
            ...interestData.data[0],
            calcMthd: calcMthd.enumNames?.[interestData.data[0].calcMthd - 1],
            balOpt: balOpt.enumNames?.[interestData.data[0].balOpt],
            disbmtOpt: disbmtOpt.enumNames[interestData.data[0].disbmtOpt],
            postRoundOpt:
              postRoundOptMap[interestData.data[0].postRoundOpt] ?? "",
            negAccrOpt:
              negAccrOpt.enumNames[interestData.data[0].negAccrOpt - 1],
            accrCarryOverOpt:
              accrCarryOverOpt.enumNames[
                interestData.data[0].accrCarryOverOpt - 1
              ],
          }
        : {};
    }
    case "componentReward": {
      const [rewardData, rewardOptions] = await Promise.all([
        getComponent(
          `${API_ROUTE_CONFIGURATION.rewardComponent}/${productName}/${version}`,
        ),
        api.options(API_ROUTE_CONFIGURATION.rewardComponent),
      ]);
      const { programType } =
        rewardOptions.actions.POST.definitions.PayoutOpt.properties;
      return rewardData.data[0]
        ? {
            ...rewardData.data[0],
            payoutOpt:
              rewardData.data[0].payoutOpt !== undefined
                ? rewardData.data[0].payoutOpt.map((option: any) => ({
                    programType: programType.enumNames[option.programType - 1],
                  }))
                : null,
          }
        : {};
    }
    case "componentRepay": {
      const [repaymentData, repaymentOptions] = await Promise.all([
        getComponent(
          `${API_ROUTE_CONFIGURATION.repaymentComponent}/${productName}/${version}`,
        ),
        api.options(API_ROUTE_CONFIGURATION.repaymentComponent),
      ]);

      const { prinMthd, intMthd, prinBalBase, pmtApplAdvance } =
        repaymentOptions.actions.POST.properties;
      const { calcMthd } =
        repaymentOptions.actions.POST.definitions.DueItems.properties;

      const calcMthdMap = calcMthd.enum.reduce(
        (acc: any, opt: any, index: number) => {
          acc[opt] = calcMthd.enumNames[index];
          return acc;
        },
        {},
      );
      return repaymentData.data[0]
        ? {
            ...repaymentData.data[0],
            dueItems: repaymentData.data[0].dueItems?.map((item: any) => ({
              ...item,
              calcMthd: calcMthdMap[item.calcMthd] ?? "",
            })),
            prinMthd: prinMthd.enumNames[repaymentData.data[0].prinMthd - 1],
            intMthd: intMthd.enumNames[repaymentData.data[0].intMthd - 1],
            prinBalBase:
              prinBalBase.enumNames[repaymentData.data[0].prinBalBase - 1],
            pmtApplAdvance:
              pmtApplAdvance.enumNames[
                repaymentData.data[0].pmtApplAdvance - 1
              ],
            calcMthd: calcMthdMap[repaymentData.data[0].calcMthdMap] ?? "",
          }
        : {};
    }
    case "componentCollateral": {
      const [collateralData, collateralOptions] = await Promise.all([
        getComponent(
          `${API_ROUTE_CONFIGURATION.componentCollateral}/${productName}/${version}`,
        ),
        api.options(API_ROUTE_CONFIGURATION.componentCollateral),
      ]);

      const { secLvl } = collateralOptions.actions.POST.properties;
      return collateralData.data[0]
        ? {
            ...collateralData.data[0],
            secLvl: secLvl.enumNames[collateralData.data[0].secLvl],
          }
        : {};
    }
    case "componentDepChrgOff": {
      const [chargeOffData, chargeOffOptions] = await Promise.all([
        getComponent(
          `${API_ROUTE_CONFIGURATION.chargeOffComponent}/${productName}/${version}`,
        ),
        api.options(API_ROUTE_CONFIGURATION.chargeOffComponent),
      ]);

      const { chrgOffOpt } = chargeOffOptions.actions.POST.properties;

      return chargeOffData.data[0]
        ? {
            ...chargeOffData.data[0],
            chrgOffOpt:
              chrgOffOpt.enumNames[chargeOffData.data[0].chrgOffOpt - 1],
          }
        : {};
    }
    case "componentTd": {
      const [termData, termOptions] = await Promise.all([
        getComponent(
          `${API_ROUTE_CONFIGURATION.termComponent}/${productName}/${version}`,
        ),
        api.options(API_ROUTE_CONFIGURATION.termComponent),
      ]);

      const { maturityOpt, rollDrGraceAdj, earlyDrPen } =
        termOptions.actions.POST.properties;

      return termData.data[0]
        ? {
            ...termData.data[0],
            maturityOpt:
              maturityOpt.enumNames[termData.data[0].maturityOpt - 1],
            rollDrGraceAdj:
              rollDrGraceAdj.enumNames[termData.data[0].rollDrGraceAdj - 1],
            earlyDrPen: earlyDrPen.enumNames[termData.data[0].earlyDrPen - 1],
          }
        : {};
    }
    case "componentTrnOpt": {
      const [transactionData] = await Promise.all([
        getComponent(
          `${API_ROUTE_CONFIGURATION.componentTrnOpt}/${productName}/${version}`,
        ),
      ]);

      return transactionData.data[0] ?? {};
    }
    case "componentNsf": {
      const [nsfData, nsfOptions, negLmtOpt] = await Promise.all([
        getComponent(
          `${API_ROUTE_CONFIGURATION.nsfComponent}/${productName}/${version}`,
        ),
        api.get(API_ROUTE_CONFIGURATION.transactionCodes),
        api.options(API_ROUTE_CONFIGURATION.nsfComponent),
      ]);

      const { negLimitStartOpt } = negLmtOpt.actions.POST.properties;
      const updatedTrnCodeExcl = nsfData.data[0].trnCodeExcl?.map(
        (code: any) => {
          const option = nsfOptions?.data?.find(
            (option: { trnCode: any; desc: any }) => option.trnCode === code,
          );
          return option?.desc || option?.trnCode;
        },
      );
      const updatedTrnCodeIncl = nsfData.data[0].trnCodeIncl?.map(
        (code: any) => {
          const option = nsfOptions?.data?.find(
            (option: { trnCode: any; desc: any }) => option.trnCode === code,
          );

          return option?.desc || option?.trnCode;
        },
      );
      const negLmtOptVal =
        negLimitStartOpt.enumNames[nsfData.data[0]?.negLimitStartOpt];
      return nsfData.data[0]
        ? {
            ...nsfData.data[0],
            trnCodeExcl: updatedTrnCodeExcl,
            trnCodeIncl: updatedTrnCodeIncl,
            negLimitStartOpt: negLmtOptVal,
          }
        : {};
    }
    case "componentLimit": {
      const [limitData, limitOptions] = await Promise.all([
        getComponent(
          `${API_ROUTE_CONFIGURATION.limitComponent}/${productName}/${version}`,
        ),
        api.options(API_ROUTE_CONFIGURATION.limitComponent),
      ]);

      const { definedBy, violationAct } =
        limitOptions.actions.POST.definitions.AccumTrnLimit.properties;

      const minBalOption = limitOptions.actions.POST.properties.minBalOpt;

      const updatedPerTrnLimits = limitData.data[0].perTrnLimits?.map(
        (limit: any) => {
          return {
            ...limit,
            definedBy: definedBy.enumNames[limit.definedBy],
            violationAct: violationAct.enumNames[limit.violationAct],
          };
        },
      );

      const updatedAccumTrnLimits = limitData.data[0].accumTrnLimits?.map(
        (limit: any) => {
          return {
            ...limit,
            definedBy: definedBy.enumNames[limit.definedBy],
            violationAct: violationAct.enumNames[limit.violationAct],
          };
        },
      );

      return limitData.data[0]
        ? {
            ...limitData.data[0],
            minBalOpt:
              minBalOption.enumNames[limitData.data[0].minBalOpt] ?? "",
            perTrnLimits: updatedPerTrnLimits,
            accumTrnLimits: updatedAccumTrnLimits,
          }
        : {};
    }
    case "componentRules": {
      const [rulesData, rulesOptions] = await Promise.all([
        getComponent(
          `${API_ROUTE_CONFIGURATION.componentRules}/${productName}/${version}`,
        ),
        api.options(API_ROUTE_CONFIGURATION.componentRules),
      ]);
      const rulesGroupsList: FinOrgRuleGroups[] = rulesData?.data[0]?.ruleGroups
        ?.length
        ? rulesData?.data[0]?.ruleGroups
        : [];
      const eventData =
        rulesOptions.actions?.POST?.definitions?.RuleGroups?.properties?.event;
      const eventObj = eventData?.enum?.length
        ? convertArrToObj(eventData.enum, eventData?.enumNames)
        : {};
      const executeAtData =
        rulesOptions.actions?.POST?.definitions?.RuleGroups?.properties
          ?.executeAt;
      const executeAtObj = executeAtData?.enum?.length
        ? convertArrToObj(executeAtData.enum, executeAtData?.enumNames)
        : {};
      return rulesData?.data[0]
        ? {
            rulesFormData: rulesData?.data[0],
            ruleGroupList: rulesGroupsList?.map((item) => {
              const obj = {
                ...item,
                event:
                  item?.event !== undefined &&
                  item?.event >= 0 &&
                  eventObj[item.event]
                    ? eventObj[item.event]
                    : "",
                executeAt:
                  item.executeAt && executeAtObj[item.executeAt]
                    ? executeAtObj[item.executeAt]
                    : "",
              };
              if (item.nextExecuteDtm) {
                const timeSplit = item?.nextExecuteDtm?.split("T");
                obj["nextExecuteDtm"] = `${timeSplit[0]} (${timeSplit[1]})`;
              }
              return obj;
            }),
          }
        : {};
    }

    default: {
      return {};
    }
  }
};

export const updateSelectedAttributeOptionsFromApi = async (
  productDetails: ProductType,
) => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  const [generalProductData, assetCatalogue, glSetData, assetClassOptionsData] =
    await Promise.all([
      api.options(API_ROUTE_CONFIGURATION.product),
      api.get(
        API_ROUTE_CONFIGURATION.assetCatalogue
      ) as Promise<AssetCatalogue>,
      api.get(API_ROUTE_CONFIGURATION.sets),
      api.options(API_ROUTE_CONFIGURATION.assetCatalogue),
    ]).then((data) => data);
  let loanDelinquencyData;
  if (productDetails?.name) {
    //fetching loan delinquency details
    loanDelinquencyData = await api
      .get(`${API_ROUTE_CONFIGURATION.loanDelinquency}/${productDetails.name}`)
      .then((data: any) => data)
      .catch((error: any) => {
        return { data: [] };
      });
  }

  const { stmtStartDtmOpt, crBureauFirstRunOpt } =
    generalProductData.actions.POST.properties;
  const { assetClass } = assetClassOptionsData.actions.POST.properties;

  const setAssetId = () => {
    const selectedAssetId = assetCatalogue?.data?.find(
      (asset) => asset.assetId === productDetails?.assetId,
    );
    return selectedAssetId?.desc
      ? `${selectedAssetId?.assetId} - ${selectedAssetId?.desc}`
      : selectedAssetId?.assetId;
  };

  const setGlSetCode = () => {
    const selectedGlSetCode = glSetData?.data?.find(
      (option: { glSetCode: string; desc: string }) =>
        option.glSetCode === productDetails?.glSetCode,
    );
    return selectedGlSetCode?.desc
      ? `${selectedGlSetCode?.glSetCode} - ${selectedGlSetCode?.desc}`
      : selectedGlSetCode?.glSetCode;
  };

  return {
    ...productDetails,
    stmtStartDtmOpt:
      stmtStartDtmOpt.enumNames[
        productDetails?.stmtStartDtmOpt ? productDetails?.stmtStartDtmOpt : 0
      ],
    assetClass:
      assetClass.enumNames[
        productDetails?.assetClass ? productDetails?.assetClass - 1 : 0
      ],

    assetId: setAssetId(),
    glSetCode: setGlSetCode(),
    crBureauFirstRunOpt:
      crBureauFirstRunOpt.enumNames[
        productDetails?.crBureauFirstRunOpt
          ? productDetails?.crBureauFirstRunOpt - 1
          : 0
      ],
    loanDelinquencyData: loanDelinquencyData?.data?.length
      ? loanDelinquencyData?.data[0]
      : "",
  };
};

// Add new xyz component flow (RJSF Form)
export const addNewComponent = async (
  url: string,
  formData: any,
  toast: ReturnType<typeof useToast>,
) => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });

  return api
    .post(url, {
      body: JSON.stringify(formData),
    })
    .then(async (res) => {
      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive("add-new")) {
        toast({
          id: "add-new",
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

// Create loan deliquency
export const createLoanDeliquency = async (
  payload: LoanDelinquencyType,
  config: ToastConfig,
) => {
  const {
    toastOptions: { toast, toastId, successMessage },
  } = config;
  const api = new ApiClient({
    baseUrl: getCoreModelEndpoint(),
  });

  return api
    .post(`${API_ROUTE_CONFIGURATION.loanDelinquency}`, {
      body: JSON.stringify(payload),
    })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }

      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

//update loan deliquency
export const updateLoanDeliquency = async (
  productName: string,
  payload: LoanDelinquencyType,
  config: ToastConfig,
) => {
  const api = new ApiClient({
    baseUrl: getCoreModelEndpoint(),
  });

  const {
    toastOptions: { toast, toastId, successMessage },
  } = config;

  return api
    .put(`${API_ROUTE_CONFIGURATION.loanDelinquency}/${productName}`, {
      body: JSON.stringify(payload),
    })
    .then(async (res) => {
      if (!toast.isActive(toastId) && successMessage) {
        toast({
          id: toastId,
          description: successMessage,
          status: "success",
        });
      }

      return res;
    })
    .catch((e) => {
      let message = e;
      if (typeof e === "object") {
        message = e.errors?.[0]?.errorDesc;
      }
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          description: message,
          status: "error",
        });
      }
      return null;
    });
};

//delete loan deliquency
export const deleteLoanDeliquency = async (productName: string) => {
  const api = new ApiClient({
    baseUrl: getCoreModelEndpoint(),
  });

  return api.delete(
    `${API_ROUTE_CONFIGURATION.loanDelinquency}/${productName}`,
  );
};

//matrix
export const createMatrixType = async (body: MatrixTypePayload) => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  const response = await api.post(`${API_ROUTE_CONFIGURATION.matrixType}`, {
    body: JSON.stringify(body),
  });
  return response;
};

export const getMatrixType = async (): Promise<any> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  const response = await api.get(
    `${API_ROUTE_CONFIGURATION.matrixType}?matrixName=${MATRIX_NAME}`,
  );
  return response;
};

export const createMartix = async (body: CreateMatrixPayload) => {
  const api = new ApiClient({ baseUrl: getCoreEndpoint() });
  const response = await api.post(
    `${API_ROUTE_CONFIGURATION.matrix}/${MATRIX_NAME}/${VALID_FROM_DTM}`,
    { body: JSON.stringify(body) },
  );
  return response;
};

export const updateMatrix = async (body: CreateMatrixPayload) => {
  const api = new ApiClient({ baseUrl: getCoreEndpoint() });
  const response = await api.put(
    `${API_ROUTE_CONFIGURATION.matrix}/${MATRIX_NAME}/${VALID_FROM_DTM}`,
    { body: JSON.stringify(body) },
  );
  return response;
};

export const getMatrix = async (): Promise<MatrixResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  const response = await api.get(
    `${API_ROUTE_CONFIGURATION.matrix}?matrixName=${MATRIX_NAME}/${VALID_FROM_DTM}`,
  );
  return response;
};

export const getDesiredProdFromMatrix = async (
  prodName: string,
): Promise<MatrixResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  const response = await api.get(
    `${API_ROUTE_CONFIGURATION.matrix}/${MATRIX_NAME}/${VALID_FROM_DTM}/${prodName}`,
  );
  return response;
};

export const deleteDesiredProdFromMatrix = async (
  prodName: string,
): Promise<MatrixResponse> => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  const response = await api.delete(
    `${API_ROUTE_CONFIGURATION.matrix}/${MATRIX_NAME}/${VALID_FROM_DTM}/${prodName}`,
  );
  return response;
};

export const addDesiredComponent = async (
  prodName: string,
  components: string[],
) => {
  try {
    // Trying to request the prod_bkCreateCmpnts matrix. A 404 response should result in a thrown error
    await getMatrixType();
  } catch {
    const payload = {
      matrixName: MATRIX_NAME,
      desc: MATRIX_TYPE_DESCRIPTION,
      valType: MATRIX_VAL_TYPE,
      dimensions: [
        {
          propFmt: MATRIX_TYPE_PROP_FMT,
          propLabel: MATRIX_TYPE_PROP_LABEL,
          propName: MATRIX_TYPE_PROP_NAME,
          propType: MATRIX_TYPE_PROP_TYPE,
          whenBtwn: MATRIX_TYPE_PROP_WHEN_BTWN,
        },
      ],
    };

    try {
      await createMatrixType(payload);
    } catch (e: any) {
      console.error(e);
    }
  }

  // Get matrix
  const matrixRs = await getDesiredProdFromMatrix(prodName)
    .then(async (res) => {
      const prodMatrix = res.data?.[0];
      prodMatrix.val = components.join(",");
      await updateMatrix({ matrixes: [prodMatrix] });
      return res;
    })
    .catch(async (err) => {
      if(components?.length) {
        const creMatrixRs = await createMartix({
          matrixes: [
            {
              dimKey: prodName,
              val: components.join(","),
              matrixName: MATRIX_NAME,
              validFromDtm: VALID_FROM_DTM,
            },
          ],
        });
        return creMatrixRs.matrixes;
      }
    });
  return matrixRs;
};

export const getCompLatestVersion = async (
  compClass: string,
  compName: string,
) => {
  const api = new ApiClient({
    baseUrl: getCoreModelEndpoint(),
  });

  return api.get(
    `/${compClass}/${compName}?filter.page=1&filter.orderBy=-version&filter.limit=1`,
  );
};

export const getCurrComp = async (
  compClass: string,
  compoName: string,
  version: number,
) => {
  const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
  return api.get(`/${compClass}/${compoName}/${version}`);
};

export const updateCompsVerAndCreateProd = async (
  prodDetails: ProductType,
  isQuickStart: boolean,
) => {
  let updateProdDetails = prodDetails;
  if (
    !isQuickStart &&
    updateProdDetails &&
    updateProdDetails.components?.length
  ) {
    updateProdDetails.components = await Promise.all(
      updateProdDetails.components.map(async (comp) => {
        const latestCompVer = await getCompLatestVersion(
          comp.componentClass,
          comp.componentName ?? "",
        );
        const currCompData = await getCurrComp(
          comp.componentClass,
          comp.componentName ?? "",
          comp.version ?? 1,
        );
        if (latestCompVer && latestCompVer.data.length) {
          currCompData.data[0].version = latestCompVer.data?.[0]?.version + 1;
        }
        await createComponent({
          url: `/${comp.componentClass}`,
          body: JSON.stringify(currCompData.data[0]),
        });
        return { ...comp, version: latestCompVer?.data?.[0]?.version + 1 };
      }),
    );
    return createProduct(updateProdDetails);
  } else {
    return createProduct(updateProdDetails);
  }
};
