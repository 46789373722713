"use client";

import { Box, Divider, Text, useToast } from "@chakra-ui/react";
import { AppFormLabel, AppModal, InputText } from "..";
import { MutableRefObject, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { getEnvStatus, getOrganizations } from "@/store";
import { getConsolePermissions } from "@/api-config/api-service";
import { createEnvironmentRequest } from "@/api-config/api-service";
import { EXPLORE_FINXACT_STEPS_NAME, TICKET_REQUESTS, ValueOf } from "@/utils";
import { API_ROUTE_CONFIGURATION, ApiClient } from "@/api-config";
import { UserContext } from "@/app/providers";

interface FinxactFeedbackFormModalProp {
  requestType: string;
  modalRef: MutableRefObject<any>;
  title: string | "";
}

function FinxactFeedbackFormModal(props: FinxactFeedbackFormModalProp) {
  const { requestType, modalRef, title } = props;
  const toast = useToast();
  const toastId = "request-finxact-modal";
  const envData = useSelector(getEnvStatus);
  const cta = envData.length
    ? "Speak with Finxact"
    : "Request a sandbox experience";

  const {
    user: { firstName, lastName, email, organization },
  } = useContext(UserContext);
  const allOrgs = useSelector(getOrganizations);
  const [requestEntered, setRequestEntered] = useState<string>("");

  async function onModalInputSave() {
    const commonToastOptions = {
      toast,
      toastId,
    };
    await createEnvironmentRequest({
      body: {
        type:
          requestType === EXPLORE_FINXACT_STEPS_NAME.sandboxOverview
            ? TICKET_REQUESTS.sandbox
            : TICKET_REQUESTS.environment,
        message: requestEntered,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: "Request sent successfully",
      },
    })
      .then(async (response) => {
        const api = new ApiClient({ baseUrl: getConsolePermissions() });
        const [data] = await Promise.all([
          api.get(`${API_ROUTE_CONFIGURATION.requests}`),
        ]).then((data) => data);
        return response;
      })
      .catch((err) => console.error(err));
    modalRef?.current.closeModal();
    // unset dropdown and input values
    setRequestEntered("");
  }

  function onModalInputCancel() {
    // Reset modal input value
    setRequestEntered("");
  }

  return (
    <AppModal
      customClass="app-modal-selectors"
      ref={modalRef}
      modalTitle={title}
      primaryBtnProp={{
        name: "Submit request",
        btnClassName: "app-btn-inverse-secondary",
      }}
      primaryBtnDisabled={requestEntered.trim() === ""}
      primaryBtnSelect={onModalInputSave}
      secondaryBtnSelect={onModalInputCancel}
      secondaryBtnProp={{
        name: "Cancel",
        btnClassName: "app-btn-link",
        style: { marginRight: "1rem" },
      }}
    >
      <Box className="app-form-field-container-small">
        <Text>
          Let’s chat. We’d love to share more about our value offering and
          answer any questions you may have.{" "}
        </Text>
      </Box>
      <Box mb={12.8} mt={4.8}>
        <AppFormLabel labelName={"First name"} isRequired={true} />
        <InputText value={firstName} disabled />
      </Box>
      <Box mb={12.8}>
        <AppFormLabel labelName={"Last name"} isRequired={true} />
        <InputText value={lastName} disabled />
      </Box>
      <Box mb={12.8}>
        <AppFormLabel labelName={"Email"} isRequired={true} />
        <InputText value={email} disabled />
      </Box>
      <Box mb={12.8}>
        <AppFormLabel labelName={"Organization"} isRequired={true} />
        <InputText value={allOrgs[organization]?.name ?? ""} disabled />
      </Box>
      <Box mb={12.8}>
        <AppFormLabel labelName={"Enter request message"} />
        <InputText
          placeholder={"Enter"}
          textarea
          value={requestEntered}
          onChange={setRequestEntered}
        />
      </Box>

      <Divider />
    </AppModal>
  );
}

export default FinxactFeedbackFormModal;
