import { Box, Flex } from "@/components/ChakraUiManager";
import { AppChildrenNode } from "@/models/global-models";
import "./TopBannerContainer.scss";
import { AppPortal } from "..";

type TopBannerContainerProp = {
  children: React.ReactNode;
  backgroundImage?: boolean;
  domId: string;
};

export default function TopBannerContainer(props: TopBannerContainerProp) {
  const { domId, children, backgroundImage = false } = props;
  return (
    <AppPortal domId={domId}>
      <Box className={backgroundImage ? "header-back-ground-image" : ""}>
        <Flex alignItems={"center"} className="hero-banner-container">
          <Box w={"100%"}>{children}</Box>
        </Flex>
      </Box>
    </AppPortal>
  );
}
