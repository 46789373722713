"use client";
import { Link, Button, Show, Hide } from "@/components/ChakraUiManager";
import "./OutlineDownloadButton.scss";
import { DownloadIcon } from "@chakra-ui/icons";

/**
 * Component for "OutlineDownloadButton" Slices.
 */
const OutlineDownloadButton = ({ location, filename }: any): JSX.Element => {
  return (
    <Link className="outline-download" href={location}>
      {/* using DownloadIcon from chakra ui as for the Image Icon, color is not possible to change. */}
      <Show above="md">
        <Button leftIcon={<DownloadIcon />} className="app-btn-reg-secondary">
          Download - {filename}
        </Button>
      </Show>
      <Hide above="md">
        <Button className="app-btn-reg-secondary">Download JSON file</Button>
      </Hide>
    </Link>
  );
};

export default OutlineDownloadButton;
